import { LogLevel, Logger } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";

// Define logger callback function
const loggerCallback = (logLevel, message, containsPii) => {
  console.log(message);
};

// Initialize logger
const logger = new Logger(loggerCallback, {
  correlationId: "1234",
  level: LogLevel.Verbose,
  piiLoggingEnabled: false
});

// MSAL configuration object
export const msalConfig = {
  auth: {
    clientId: "ed93db57-4e87-4d55-96f5-d0cb9c60ef07",
    authority:
      "https://login.microsoftonline.com/0b031fc9-84e1-487c-945f-e6dbd618cd02",
    redirectUri: "https://mojo-test.taylorfarms.com/"
    //redirectUri: "https://mojo-test.taylorfarms.com/"
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    AccessTokenLifetime: 900 // Set this to "true" if you are having issues on IE11 or Edge
  }
  // system: {
  //     logger: logger,
  //     tokenRenewalOffsetSeconds: 300
  // }
};

// Request object for login
export const loginRequest = {
  scopes: ["User.Read", "openid"]
};

// Microsoft Graph API configuration
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
