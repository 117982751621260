export const defaultRows = 10;
export const rowsPerPageOptions = [10, 25, 50, 100, 150];
export const paginator = true;
export const scrollable = true;
export const emptyMessage = "No records found.";
export const tableStyle = { minWidth: "50rem" };
export const currentPageReportTemplate =
  "Showing {first} to {last} of {totalRecords} entries";
export const paginatorTemplate =
  "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
export const showStatusOptions = {
  All: null,
  Pending: 1,
  Submitted: 2,
  Processed: 3,
  Failed: 4,
  Cancelled: 5
};
export const showStatusKeyValueOptions = {
  1: "Pending",
  2: "Submitted",
  3: "Processed",
  4: "Failed",
  5: "Cancelled"
};
export const ediFileContentShowOptions = {
  EDIFile: "EDIFile",
  EDIPurchaseOrder: "EDIPurchaseOrder"
};
