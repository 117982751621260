import {api} from "../api/api";

const orderSummaryApi = async (formbody) => {
  try {

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }
    const response = await api.post("/api/Orders/Summary", formbody, config );
    return response.data?.result?.result || [];
  } catch (error) {
    if(error?.code?.trim() == "ERR_NETWORK"){
      throw new Error("It looks like we can't reach the server right now. Please try refreshing the page or check after sometime.");
    }
    else{
      throw new Error(error?.response?.data?.title);
    }
  }
};

export default orderSummaryApi;