"use client";

import React from "react";
import { useState, useContext } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import CreateOrderForm from "./container/create-order/create-order-form";
import FunctionSetUp from "./container/Function-setup/functionSetup";
import LogSummary from "./container/log-summary/logSummary";
import Card from "./components/shared-card/card";
// import Dashboard from "./dashboard/page";

import Grid from "@mui/material/Grid";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import SideNav from "./components/side-nav";
import Navbar from "./components/header-nav";
import EdiPurchaseOrders from "./container/Edi-Purchase-Order-Process/ediPurchaseOrder";
import FeatureFlagDataContext from "./store/featureFlags";

export default function OfpSegmentsLayout({ children }) {
  const location = useLocation();
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  let headingTitle = "";
  const heading = location?.pathname.toLocaleLowerCase();
  // const showAutoLog = JSON.parse(
  //   localStorage.getItem("ApplicationSettings")
  // )?.enableAutoLog;
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);

  const toggleMenu = (toggleFlag) => {
    setIsMenuExpanded(toggleFlag);
  };
  if (heading.includes("create")) {
    headingTitle = "Create Order";
  } else if (heading.includes("function")) {
    headingTitle = "Function Setup";
  } else if (heading.includes("logsummary")) {
    headingTitle = "Log Summary";
  } else if (heading.includes("autolog")) {
    headingTitle = "Automation Log Summary";
  } else if (heading.includes("edi")) {
    headingTitle = "Switchboard Routing";
  }

  const getToken = (token) => {
    console.log(token);
  };
  return (
    <Grid container className="containerBlock">
      <Grid
        item
        xs={12}
        md={isMenuExpanded ? 2 : 1}
        className="transition menuContainer"
      >
        <SideNav isMenuExpanded={isMenuExpanded} toggleMenu={toggleMenu} />
      </Grid>
      <Grid
        item
        xs={12}
        md={isMenuExpanded ? 10 : 11}
        className="mainContainer transition"
      >
        <Navbar heading={headingTitle} getTokenHandler={getToken} />
        <Card>
          <Routes>
            {/* <Route path="dashboard" element={<Dashboard />}></Route> */}
            <Route path="orders/create" element={<CreateOrderForm />} />
            <Route path="orders/" element={<CreateOrderForm />} />
            <Route path="administration/function" element={<FunctionSetUp />} />
            <Route path="/orders/logsummary" element={<LogSummary />} />
            {featureFlags?.enableAutoLog && (
              <Route path="/orders/autoLog" element={<LogSummary />} />
            )}
            <Route
              path="/orders/ediPurchaseOrder"
              element={<EdiPurchaseOrders />}
            />

            {/* <Route path="/" element={<h1>{ <CreateOrderForm />}</h1>} /> */}
            <Route
              path="*"
              element={<h1>{"404 This page could not be found."}</h1>}
            />
          </Routes>
        </Card>
        <div style={{ paddingTop: "30px" }}>
          <hr
            style={{
              height: "0.5px",
              borderWidth: "0",
              color: "gray",
              backgroundColor: "#d6d6d6"
            }}
          ></hr>
          <p
            style={{
              color:
                "var(--Light-Text-Default-Text, var(--BlueGray-BlueGray-700, #334155))",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px"
            }}
          >
            Copyright &#169; 2024 Taylor Farms. All rights reserved.
          </p>
        </div>
      </Grid>
    </Grid>
    //   <div className="grid containerBlock">
    //   <div
    //   className={`col-12 ${isMenuExpanded ? 'lg:col-3' : 'lg:col-1'} transition menuContainer`}
    //   >
    //     <SideNav isMenuExpanded={isMenuExpanded} toggleMenu={toggleMenu} />
    //   </div>
    //   <div
    //   className={`col-12 ${isMenuExpanded ? 'lg:col-9' : 'lg:col-11'} mainContainer transition`}
    //   >
    //     <Navbar />
    //     <Card>
    //       <Routes>
    //         <Route path="dashboard" element={<Dashboard />}></Route>
    //         <Route path="orders/create-order" element={<CreateOrderForm />} />
    //         <Route path="orders/" element={<CreateOrderForm />} />

    //         <Route path="/" element={<h1>{ '404 This page could not be found.' }</h1>} />
    //         <Route path="*" element={<h1>{ '404 This page could not be found.' }</h1>} />
    //       </Routes>
    //     </Card>
    //   </div>
    // </div>
  );
}
