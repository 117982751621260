import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Spinner from "../../components/spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import * as actionTypes from "../../store/actions";
import { Dropdown } from "primereact/dropdown";
import {
  cancelEdiPurchaseOrders,
  getEdiFileContent,
  getReprocessEdiPurchaseOrders,
  revertCancelledEdiPurchaseOrders,
  updateEdiPurchaseOrder
} from "../../services/ediPurchaseOrder";
import { getEdiPurchaseOrders } from "../../services/ediPurchaseOrder";
import { reProcessEdiPurchaseOrder } from "../../services/ediPurchaseOrder";
import { format, parseISO } from "date-fns";
import {
  ediFileContentShowOptions,
  showStatusOptions
} from "../../constants/constants";
import { showStatusKeyValueOptions } from "../../constants/constants";
import { Tag } from "primereact/tag";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { ediFilePurchaseOrderReProcessColumns } from "../../constants/table-columns/tableColumns";

const Table = (props) => {
  const {
    content: data,
    filterValuesData: currentFilterValuesData,
    columnsData: columns,
    tableStyle,
    paginatorTemplate,
    paginator,
    scrollable,
    rows,
    rowsPerPageOptions,
    emptyMessage,
    currentPageReportTemplate,
    rowExpansionColumnsData,
    styles,
    sendFetchLatestDuplicatePOsToParent
  } = props;
  const [ediPurchaseOrderTempData, setEdiPurchaseOrderTempData] =
    useState(data);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ediPurchaseOrderUpdateObjs, setEdiPurchaseOrderUpdateObjs] = useState(
    []
  );
  const [ediPOReProcessDynamicObj, setEdiPOReProcessDynamicObj] = useState([]);
  const [ediPOReProcessStaticObj, setEdiPOReProcessStaticObj] = useState([]);
  const [ediPOReProcessUpdateObj, setEdiPOReProcessUpdateObj] = useState([]);
  const [cancelEdiPOIds, setCancelEdiPOIds] = useState([]);
  const [revertCancelEdiPOIds, setRevertCancelEdiPOIds] = useState([]);
  const [ediFileContentHeaderTitle, setEdiFileContentHeaderTitle] =
    useState(null);
  const [ediFileContentBody, setEdiFileContentBody] = useState(null);
  const [ediFileContentFormatStatus, setEdiFileContentFormatStatus] =
    useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const { getWalmartRegions } = bindActionCreators(actionCreators, dispatch);
  let regionListCodeToNamePairs = {};
  const walmartRegionList = useSelector((state) => {
    const data = state.orderReducer.walmartRegions;

    const transformedRegions = data.map((region) => {
      regionListCodeToNamePairs[region.regionCode] = region.regionName;

      return {
        label: region.regionName + " (" + region.regionCode + ")",
        value: region.regionCode
      };
    });

    return transformedRegions;
  });
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const [cancelSubmitDialogVisible, setCancelSubmitDialogVisible] =
    useState(false);
  const [reProcessModalVisible, setReProcessModalVisible] = useState(false);
  const [ediFileContentModalVisible, setEdiFileContentModalVisible] =
    useState(false);
  const [revertCancelSubmitDialogVisible, setRevertCancelSubmitDialogVisible] =
    useState(false);

  useEffect(() => {
    getWalmartRegions();
  }, []);

  useEffect(() => {
    setEdiPurchaseOrderTempData(data);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
  }, [data]);

  const processData = async () => {
    let isProcessSuccess = false;
    try {
      setShowSpinner(true);

      const response = await updateEdiPurchaseOrder(ediPurchaseOrderUpdateObjs);
      isProcessSuccess = true;
      sendFetchLatestDuplicatePOsToParent();

      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Update Failed",
        detail: error.message,
        life: 3000
      });
    }

    setShowSpinner(true);
    const result = await getEdiPurchaseOrders(currentFilterValuesData);

    setEdiPurchaseOrderTempData(result);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
    setShowSpinner(false);

    if (isProcessSuccess) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Edi Purchase Order updated successfully",
        life: 3000
      });
    }
  };

  const reProcessData = async () => {
    let isProcessSuccess = false;
    try {
      setShowSpinner(true);

      for (let i = 0; i < ediPOReProcessDynamicObj.length; i++) {
        if (
          ediPOReProcessDynamicObj[i].actualFulfillmentRegion !==
          ediPOReProcessStaticObj[i].actualFulfillmentRegion
        ) {
          const extractedObject = {
            ediPurchaseOrderId: ediPOReProcessDynamicObj[i].ediPurchaseOrderId,
            actualFulfilmentRegion:
              ediPOReProcessDynamicObj[i].actualFulfillmentRegion
          };

          ediPOReProcessUpdateObj.push(extractedObject);
        }
      }

      if (ediPOReProcessUpdateObj.length === 0) {
        setShowSpinner(false);
        toast.current.show({
          severity: "error",
          summary: "Validation Error",
          detail:
            "You can't reprocess without modifying the region for atleast one PO",
          life: 3000
        });

        setEdiPOReProcessUpdateObj([]);
        return;
      }

      const response = await reProcessEdiPurchaseOrder(ediPOReProcessUpdateObj);
      isProcessSuccess = true;
      sendFetchLatestDuplicatePOsToParent();

      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Reprocess Failed",
        detail: error.message,
        life: 3000
      });
    }

    setShowSpinner(true);
    const result = await getEdiPurchaseOrders(currentFilterValuesData);

    setEdiPurchaseOrderTempData(result);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
    setEdiPOReProcessDynamicObj([]);
    setEdiPOReProcessUpdateObj([]);
    setEdiPOReProcessStaticObj([]);
    setReProcessModalVisible(false);
    setShowSpinner(false);

    if (isProcessSuccess) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Edi Purchase Order reprocessed successfully",
        life: 3000
      });
    }
  };

  const onActualFulfillmentRegionChange = (e, index, rowData) => {
    const updatedData = ediPurchaseOrderTempData.map((record) => {
      if (record.ediPurchaseOrderId === rowData.ediPurchaseOrderId) {
        if (e.value === undefined) {
          return { ...record, actualFulfillmentRegion: null };
        } else {
          return { ...record, actualFulfillmentRegion: e.value };
        }
      }
      return record;
    });

    setEdiPurchaseOrderTempData(updatedData);
  };

  const actualFulfillmentRegionTemplate = (rowData) => {
    const index = ediPurchaseOrderTempData.indexOf(rowData);

    if (
      rowData.actualFulfillmentRegion &&
      rowData.processingStatus !== showStatusOptions.Pending
    ) {
      return regionListCodeToNamePairs[rowData.actualFulfillmentRegion];
    } else {
      if (rowData.processingStatus !== showStatusOptions.Cancelled) {
        return (
          <Dropdown
            value={rowData.actualFulfillmentRegion}
            options={walmartRegionList}
            onChange={(e) => onActualFulfillmentRegionChange(e, index, rowData)}
            className={
              rowData.isDuplicatePO ? `${styles.dropdownDuplicate}` : null
            }
            showClear
            placeholder="Select a region"
          />
        );
      }
    }
  };

  function formatDateTime(isoString) {
    const date = parseISO(isoString);
    return format(date, "yyyy-MM-dd");
  }

  const ediFileCreatedDateUTCFormat = (data) => {
    return formatDateTime(data.ediFileCreatedDateUTC);
  };

  const deliveryDateFormat = (data) => {
    return formatDateTime(data.deliveryDate);
  };

  const onProcessingStatusChange = async (data) => {
    try {
      setShowSpinner(true);

      const response = await getReprocessEdiPurchaseOrders({
        purchaseOrderId: data.ediPurchaseOrderId
      });

      setShowSpinner(false);
      setEdiPOReProcessStaticObj(response);
      setEdiPOReProcessDynamicObj(response);
      setReProcessModalVisible(true);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Reprocess Request Failed",
        detail: error.message,
        life: 3000
      });
    }
  };

  const processingStatusFormat = (data) => {
    let severityValue = "";

    switch (data.processingStatus) {
      case showStatusOptions.Pending:
        severityValue = "secondary";
        break;
      case showStatusOptions.Submitted:
        severityValue = "info";
        break;
      case showStatusOptions.Processed:
        severityValue = "success";
        break;
      case showStatusOptions.Failed:
        severityValue = "danger";
        break;
      case showStatusOptions.Cancelled:
        severityValue = "warning";
        break;
      default:
        severityValue = "";
    }

    return (
      <div>
        <Tag
          severity={severityValue}
          value={showStatusKeyValueOptions[data.processingStatus]}
          rounded
          style={
            data.processingStatus === showStatusOptions.Failed
              ? { marginRight: "2.2rem" }
              : data.processingStatus === showStatusOptions.Submitted
              ? { marginRight: "0.8rem" }
              : data.processingStatus === showStatusOptions.Processed
              ? { marginRight: "0.8rem", marginBottom: "0.3rem" }
              : data.processingStatus === showStatusOptions.Cancelled
              ? {
                  background: "black",
                  color: "white",
                  marginRight: "1rem",
                  marginBottom: "0.3rem"
                }
              : { marginRight: "1.4rem", marginBottom: "0.3rem" }
          }
        ></Tag>
        {data.processingStatus === showStatusOptions.Pending && (
          <Button
            icon="pi pi-trash"
            severity="warning"
            aria-label="Edit"
            className={
              data.isDuplicatePO ? `${styles.btnsDuplicate}` : `${styles.btns}`
            }
            onClick={() => onCancelEdiPO(data)}
            tooltip="Cancel"
          />
        )}

        {data.processingStatus === showStatusOptions.Processed && (
          <>
            <Button
              icon="pi pi-trash"
              severity="warning"
              aria-label="Edit"
              className={
                data.isDuplicatePO
                  ? `${styles.btnsDuplicate}`
                  : `${styles.btns}`
              }
              onClick={() => onCancelEdiPO(data)}
              tooltip="Cancel"
            />
            <Button
              icon="pi pi-pencil"
              severity="warning"
              aria-label="Edit"
              className={
                data.isDuplicatePO &&
                data.processingStatus === showStatusOptions.Pending
                  ? `${styles.btnsDuplicate}`
                  : `${styles.btns}`
              }
              onClick={() => onProcessingStatusChange(data)}
              tooltip="Reprocess"
            />
          </>
        )}
        {data.processingStatus === showStatusOptions.Cancelled && (
          <Button
            icon="pi pi-undo"
            severity="warning"
            aria-label="Edit"
            className={`${styles.btns}`}
            onClick={() => onRevertCancelEdiPO(data)}
            tooltip="Revert"
          />
        )}
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData?.ediPurchaseOrderLineItemsList?.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-1 pl-5 ml-5">
        <DataTable
          value={data.ediPurchaseOrderLineItemsList}
          emptyMessage={emptyMessage}
        >
          {rowExpansionColumnsData?.map((col, i) =>
            col?.columnType?.toLocaleLowerCase() === "fun" ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth }}
                headerStyle={{ minWidth: col.minWidth }}
              />
            ) : (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth }}
                headerStyle={{ minWidth: col.minWidth }}
              />
            )
          )}
        </DataTable>
      </div>
    );
  };

  const toggleRows = () => {
    if (allExpanded) {
      setExpandedRows(null);
      setAllExpanded(false);
    } else {
      let newExpandedRows = {};
      ediPurchaseOrderTempData.forEach(
        (p) => (newExpandedRows[`${p.ediPurchaseOrderId}`] = true)
      );
      setExpandedRows(newExpandedRows);
      setAllExpanded(true);
    }
  };

  const handleSelectAll = (e) => {
    if (e.checked) {
      const allIds = ediPurchaseOrderTempData
        .filter((item) => item.processingStatus === showStatusOptions.Pending)
        .map((item) => item.ediPurchaseOrderId);

      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const headerCheckbox = (
    <Checkbox
      onChange={handleSelectAll}
      checked={
        selectedIds.length > 0 &&
        selectedIds.length ===
          ediPurchaseOrderTempData.filter(
            (item) => item.processingStatus === showStatusOptions.Pending
          ).length
      }
      onClick={(e) => e.stopPropagation()}
    />
  );

  const onCheckboxChange = (e, rowData) => {
    let newSelectedIds = [...selectedIds];
    if (e.checked) {
      if (!newSelectedIds.includes(rowData.ediPurchaseOrderId)) {
        newSelectedIds.push(rowData.ediPurchaseOrderId);
      }
    } else {
      newSelectedIds = newSelectedIds.filter(
        (id) => id !== rowData.ediPurchaseOrderId
      );
    }
    setSelectedIds(newSelectedIds);
  };

  const checkboxColumnTemplate = (rowData) => {
    if (rowData.processingStatus === showStatusOptions.Pending) {
      return (
        <Checkbox
          onChange={(e) => onCheckboxChange(e, rowData)}
          checked={selectedIds.includes(rowData.ediPurchaseOrderId)}
          onClick={(e) => e.stopPropagation()}
        />
      );
    }
  };

  const submitEdiOrdersAccept = () => {
    setShowSpinner(true);

    for (let i = 0; i < selectedIds.length; i++) {
      const result = ediPurchaseOrderTempData.find(
        (itemRecord) => itemRecord.ediPurchaseOrderId === selectedIds[i]
      );

      if (result.actualFulfillmentRegion === null) {
        setShowSpinner(false);

        setEdiPurchaseOrderUpdateObjs([]);

        toast.current.show({
          severity: "error",
          summary: "Validation Error",
          detail: "Please select region for all selected PO's",
          life: 3000
        });

        return;
      }

      if (
        result.actualFulfillmentRegion !== null &&
        result.processingStatus === showStatusOptions.Pending
      ) {
        const extractedObject = {
          ediPurchaseOrderId: result.ediPurchaseOrderId,
          ediFileId: result.ediFileId,
          actualFulfillmentRegion: result.actualFulfillmentRegion,
          processingStatus: showStatusOptions.Submitted
        };

        ediPurchaseOrderUpdateObjs.push(extractedObject);
      }
    }

    if (ediPurchaseOrderUpdateObjs.length === 0) {
      setShowSpinner(false);

      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "No PO selected",
        life: 3000
      });

      return;
    }

    setShowSpinner(false);
    setSubmitDialogVisible(true);
  };

  const submitEdiOrdersReject = () => {
    setEdiPurchaseOrderUpdateObjs([]);
    setSubmitDialogVisible(false);
  };

  const footerContent = (
    <div className="col-12 grid">
      <div className="col-12 md:col-2">
        <Button
          label="Submit"
          size="medium"
          className={styles.primaryBtn}
          onClick={submitEdiOrdersAccept}
          autoFocus
        />
      </div>
    </div>
  );

  const reProcessHeaderElement = (
    <div>
      <div className="inline-flex align-items-center justify-content-center gap-2">
        <span className="font-bold white-space-nowrap">
          EDI850 Purchase Orders Reprocess
        </span>
      </div>
      <div className="text-sm" style={{ marginTop: "0.5rem", color: "red" }}>
        <span className="pi pi-exclamation-triangle"></span>
        <span style={{ marginLeft: "0.2rem" }}>
          Please make sure to cancel previously submitted PO in AX/ D365.
        </span>
      </div>
    </div>
  );

  const reProcessFooterContent = (
    <div>
      <Button
        label="Resubmit"
        icon="pi pi-spinner"
        onClick={reProcessData}
        autoFocus
      />
    </div>
  );

  const onHideEdiReProcessModal = () => {
    setEdiPOReProcessDynamicObj([]);
    setEdiPOReProcessUpdateObj([]);
    setEdiPOReProcessStaticObj([]);
    setReProcessModalVisible(false);
  };

  const onActualFulfillmentRegionReProcessChange = (e, rowData) => {
    const updatedData = ediPOReProcessDynamicObj.map((record) => {
      if (record.ediPurchaseOrderId === rowData.ediPurchaseOrderId) {
        if (e.value === undefined) {
          return { ...record, actualFulfillmentRegion: null };
        } else {
          return { ...record, actualFulfillmentRegion: e.value };
        }
      }
      return record;
    });

    setEdiPOReProcessDynamicObj(updatedData);
  };

  const actualFulfillmentRegionReProcessTemplate = (rowData) => {
    return (
      <Dropdown
        value={rowData.actualFulfillmentRegion}
        options={walmartRegionList}
        onChange={(e) => onActualFulfillmentRegionReProcessChange(e, rowData)}
        placeholder="Select a region"
      />
    );
  };

  const ediPONumberFileContentFormat = (rowData) => {
    return rowData.processingStatus === showStatusOptions.Processed ? (
      <Button
        text
        onClick={() =>
          ediFileContent({
            id: rowData.ediPurchaseOrderId,
            edi850FileType: ediFileContentShowOptions.EDIPurchaseOrder
          })
        }
        style={{ color: "#22c55e", textDecoration: "none" }}
        className="-ml-3"
      >
        {rowData.poNumber}
      </Button>
    ) : (
      rowData.poNumber
    );
  };

  const ediFileNameFileContentFormat = (rowData) => {
    return (
      <Button
        text
        onClick={() =>
          ediFileContent({
            id: rowData.ediFileId,
            edi850FileType: ediFileContentShowOptions.EDIFile
          })
        }
        style={{ color: "#22c55e", textDecoration: "none" }}
        className="white-space-nowrap"
      >
        {rowData.ediFileName}
      </Button>
    );
  };

  const ediBuyingPartyNameFormat = (rowData) => {
    return <div className="white-space-nowrap">{rowData.buyingPartyName}</div>;
  };

  const ediSupplierNameFormat = (rowData) => {
    return <div className="white-space-nowrap">{rowData.supplierName}</div>;
  };

  const ediFileContent = async (ediFileContentObj) => {
    try {
      setShowSpinner(true);

      const response = await getEdiFileContent(ediFileContentObj);

      setEdiFileContentHeaderTitle(response.ediFileTitle);
      setEdiFileContentBody(response.ediFileContent);

      setShowSpinner(false);
      setEdiFileContentModalVisible(true);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "File Content Request Failed",
        detail: error.message,
        life: 3000
      });
    }
  };

  const formatEdiFileContent = () => {
    if (ediFileContentBody && !ediFileContentFormatStatus) {
      const formattedEdiFileContent = ediFileContentBody.split("~").join("~\n");
      setEdiFileContentBody(formattedEdiFileContent);
      setEdiFileContentFormatStatus(true);
    }
  };

  const ediFileContentModalHeader = (
    <div className="flex justify-content-between flex-wrap">
      <div className="flex align-items-center justify-content-center">
        <span className="font-bold white-space-nowrap text-base">
          {ediFileContentHeaderTitle}
        </span>
      </div>
      <div className="flex align-items-center justify-content-center">
        <Button
          label="Format"
          icon="pi pi-file-edit"
          onClick={formatEdiFileContent}
          style={{ marginRight: "1rem" }}
          autoFocus
          disabled={!ediFileContentBody}
        />
      </div>
    </div>
  );

  const onHideEdiFileContentModal = () => {
    setEdiFileContentFormatStatus(false);
    setEdiFileContentModalVisible(false);
  };

  const onCancelEdiPO = (data) => {
    const extractedObj = {
      ediPurchaseOrderId: data.ediPurchaseOrderId,
      poNumber: data.poNumber
    };

    setCancelEdiPOIds([extractedObj]);
    setCancelSubmitDialogVisible(true);
  };

  const cancelEdiPOReject = () => {
    setCancelEdiPOIds([]);
    setCancelSubmitDialogVisible(false);
  };

  const cancelEdiPOAccept = async () => {
    let isProcessSuccess = false;
    try {
      setShowSpinner(true);

      const response = await cancelEdiPurchaseOrders([
        cancelEdiPOIds[0].ediPurchaseOrderId
      ]);
      isProcessSuccess = true;
      sendFetchLatestDuplicatePOsToParent();

      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Cancel Failed",
        detail: error.message,
        life: 3000
      });
    }

    setShowSpinner(true);
    const result = await getEdiPurchaseOrders(currentFilterValuesData);

    setEdiPurchaseOrderTempData(result);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
    setCancelEdiPOIds([]);
    setShowSpinner(false);

    if (isProcessSuccess) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail:
          "EDI PO# " + cancelEdiPOIds[0].poNumber + " cancelled successfully.",
        life: 3000
      });
    }
  };

  const onRevertCancelEdiPO = (data) => {
    const extractedObj = {
      ediPurchaseOrderId: data.ediPurchaseOrderId,
      poNumber: data.poNumber
    };

    setRevertCancelEdiPOIds([extractedObj]);
    setRevertCancelSubmitDialogVisible(true);
  };

  const revertCancelEdiPOReject = () => {
    setRevertCancelEdiPOIds([]);
    setRevertCancelSubmitDialogVisible(false);
  };

  const revertCancelEdiPOAccept = async () => {
    let isProcessSuccess = false;
    try {
      setShowSpinner(true);

      const response = await revertCancelledEdiPurchaseOrders([
        revertCancelEdiPOIds[0].ediPurchaseOrderId
      ]);
      isProcessSuccess = true;
      sendFetchLatestDuplicatePOsToParent();

      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Revert Failed",
        detail: error.message,
        life: 3000
      });
    }

    setShowSpinner(true);
    const result = await getEdiPurchaseOrders(currentFilterValuesData);

    setEdiPurchaseOrderTempData(result);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
    setCancelEdiPOIds([]);
    setRevertCancelEdiPOIds([]);
    setShowSpinner(false);

    if (isProcessSuccess) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail:
          "EDI PO# " +
          revertCancelEdiPOIds[0].poNumber +
          " reverted successfully.",
        life: 3000
      });
    }
  };

  const rowClass = (data) => {
    if (
      data.isDuplicatePO &&
      data.processingStatus === showStatusOptions.Pending
    ) {
      return "bg-yellow-100";
    }
  };

  return (
    <>
      {showSpinner && <Spinner />}

      <ConfirmDialog
        visible={submitDialogVisible}
        onHide={submitEdiOrdersReject}
        message={
          ediPurchaseOrderUpdateObjs.length + " Po(s) are about to submit."
        }
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={processData}
        reject={submitEdiOrdersReject}
      />

      <ConfirmDialog
        visible={cancelSubmitDialogVisible}
        onHide={cancelEdiPOReject}
        message={
          cancelEdiPOIds.length > 0
            ? "EDI PO# " + cancelEdiPOIds[0].poNumber + " is about to cancel."
            : null
        }
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={cancelEdiPOAccept}
        reject={cancelEdiPOReject}
      />

      <ConfirmDialog
        visible={revertCancelSubmitDialogVisible}
        onHide={revertCancelEdiPOReject}
        message={
          revertCancelEdiPOIds.length > 0
            ? "EDI PO# " +
              revertCancelEdiPOIds[0].poNumber +
              " is about to revert from Cancelled to Pending Status."
            : null
        }
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={revertCancelEdiPOAccept}
        reject={revertCancelEdiPOReject}
      />

      <Dialog
        visible={ediFileContentModalVisible}
        modal
        header={ediFileContentModalHeader}
        style={{ width: "70vw" }}
        breakpoints={{ "1024px": "75vw", "641px": "100vw" }}
        onHide={onHideEdiFileContentModal}
        maximizable
      >
        <pre style={{ whiteSpace: "pre-wrap" }}>{ediFileContentBody}</pre>
      </Dialog>

      <Dialog
        visible={reProcessModalVisible}
        modal
        header={reProcessHeaderElement}
        footer={reProcessFooterContent}
        style={{ width: "50vw" }}
        breakpoints={{ "1024px": "75vw", "641px": "100vw" }}
        onHide={onHideEdiReProcessModal}
        maximizable
      >
        <DataTable
          value={ediPOReProcessDynamicObj}
          tableStyle={tableStyle}
          paginatorTemplate={paginatorTemplate}
          paginator={paginator}
          className={`${tableStyle.dataTable}`}
          rows={rows}
          // footer={footerContent}
          rowsPerPageOptions={rowsPerPageOptions}
          emptyMessage={emptyMessage}
          currentPageReportTemplate={currentPageReportTemplate}
          dataKey="ediPurchaseOrderId"
        >
          {ediFilePurchaseOrderReProcessColumns?.map((col, i) => (
            <Column
              key={col?.field}
              field={col?.field}
              header={col?.header}
              sortable={col?.sort}
              style={{ minWidth: col?.minWidth }}
              headerStyle={{ minWidth: col.minWidth }}
              body={
                col.field.toLocaleLowerCase() === "actualfulfillmentregion"
                  ? actualFulfillmentRegionReProcessTemplate
                  : col.value
              }
            />
          ))}
        </DataTable>
      </Dialog>

      <DataTable
        value={ediPurchaseOrderTempData}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        className={`${tableStyle.dataTable}`}
        rows={rows}
        footer={footerContent}
        rowsPerPageOptions={rowsPerPageOptions}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="ediPurchaseOrderId"
        scrollable={scrollable}
        scrollHeight="30vw"
        rowClassName={rowClass}
      >
        <Column
          style={{ width: "10px" }}
          header={headerCheckbox}
          body={checkboxColumnTemplate}
        />

        <Column
          header={
            <Button
              className={allExpanded ? "pi pi-angle-down" : "pi pi-angle-right"}
              style={{
                fontSize: "1.3rem",
                color: "#6c757d",
                backgroundColor: "#f8f9fa",
                border: "none",
                padding: "2px"
              }}
              onClick={toggleRows}
            ></Button>
          }
          expander={allowExpansion}
          style={{ width: "10px" }}
        />

        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            style={{ minWidth: col?.minWidth }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col.field.toLocaleLowerCase() === "actualfulfillmentregion"
                ? actualFulfillmentRegionTemplate
                : col.field.toLocaleLowerCase() === "edifilecreateddateutc"
                ? ediFileCreatedDateUTCFormat
                : col.field.toLocaleLowerCase() === "deliverydate"
                ? deliveryDateFormat
                : col.field.toLocaleLowerCase() === "processingstatus"
                ? processingStatusFormat
                : col.field.toLocaleLowerCase() === "ponumber"
                ? ediPONumberFileContentFormat
                : col.field.toLocaleLowerCase() === "edifilename"
                ? ediFileNameFileContentFormat
                : col.field.toLocaleLowerCase() === "buyingpartyname"
                ? ediBuyingPartyNameFormat
                : col.field.toLocaleLowerCase() === "suppliername"
                ? ediSupplierNameFormat
                : col.value
            }
          />
        ))}
      </DataTable>

      <Toast ref={toast} position="center" />
    </>
  );
};

export default Table;
