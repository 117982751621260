import { useEffect, useState,useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import Table from "../../components/Table/Table";
import styles from "./functionList.module.scss";
import globalStyles from "./functionSetup.module.scss";
import { Button } from "primereact/button";
import { FunctionHeader } from "../../components/Table/table-header/tableHeader";
import { Functioncolumns } from "../../constants/table-columns/tableColumns"
import { functionDelete } from "../../services/functionsetup";
import * as constants from "../../constants/constants";
import Spinner from "../../components/spinner/spinner";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog"; 
import LoginUserDataContext from "../../store/login";
import FeatureFlagDataContext from "../../store/featureFlags";
import {sortData} from "../../constants/dataSort";
import { appInsights } from '../../AppInsights';

const FunctionList = (props) => {
  const toast = useRef(null);
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);
  const [functionList, setFunctionList] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const [filteredValue, setFilteredValue] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteFunctionId, setDeleteFunctionId] = useState(null);
  const [userData, setUserData] = useContext(LoginUserDataContext)
  const dispatch = useDispatch();
  const { getAllFunctions } = bindActionCreators(actionCreators, dispatch);
  const functionsListCopy = useSelector((state) => {
    return state.orderReducer.allfunctions;
  });

  useEffect(() => {
    fetchFunctions();
  }, []);
  useEffect(() => {
    setFunctionList(functionsListCopy);
    setFilteredValue({
      regionName: "All",
      regionId: "0"
    });
    if(searchedItem?.trim() !== "" || filteredValue?.regionId == "0" ){
      DataFilterLogic(searchedItem, filteredValue)
    }
  }, [functionsListCopy]);

  const dropdownOptions = Array.from(
    functionsListCopy
      .reduce((m, t) => m.set(t.regionName, t), new Map())
      .values()
  );
  const filterOptionsList = sortData('regionName',[
    ...dropdownOptions,
    {
      regionName: "All",
      regionId: "0"
    }
  ]);

  const fetchFunctions = async () => {
    try {
      const params = {
        statusId: null,
        isLogSummary:false
      };
      getAllFunctions({params});
      setShowSpinner(false)
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while fetching function list in function setup screen",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const onSearch = (event) => {
    const searchedWord = event.target.value;
    DataFilterLogic(searchedWord, filteredValue);
  };

  const onFilter = (event) => {
    const selectedFilteredValue = event.value;
    DataFilterLogic(searchedItem, selectedFilteredValue);
  };

  const DataFilterLogic = (searchedItem, filteredValue) => {
    setSearchedItem(searchedItem);
    setFilteredValue(filteredValue);
    if (searchedItem.trim() == "" && filteredValue?.regionId == "0") {
      setFunctionList(functionsListCopy);
    } else if (filteredValue?.regionId == "0" && searchedItem.trim() != "") {
      const data = functionsListCopy.filter((item) =>
        Object.values(item).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchedItem.toLowerCase())
          )
      );
      setFunctionList(data);
    } else if (filteredValue?.regionId != "0" && searchedItem.trim() == "") {
      const data = functionsListCopy.filter(
        (item) => item.regionId === filteredValue?.regionId
      );
      setFunctionList(data);
    } else {
      const data = functionsListCopy.filter(
        (item) =>
        Object.values(item).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchedItem.toLowerCase())
          ) &&
          item.regionId === filteredValue?.regionId
      );
      setFunctionList(data);
    }
  };

  const navigateToFunctionForm = (formState,functionId) => {
    props?.alterComponent(false,formState,functionId);
  }

  const deleteFunction = (functionId) => {
   setShowConfirmDialog(true);
   setDeleteFunctionId(functionId)
  }
  const accept = async () => {
   try{
    setShowSpinner(true);
    const result = await functionDelete(deleteFunctionId);
    fetchFunctions();
    setShowSpinner(false);
    // setSearchedItem("")
    // setFilteredValue(null)
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: result,
      life: 4000
    });
   }
   catch(error){
    setShowSpinner(false);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error.message,
      life: 4000
    });
  }
  };
  const cancel = () => {
   setShowSpinner(false); 
   setShowConfirmDialog(false)
  }
  
  return (
    <>
    {showSpinner && <Spinner/>}
      <div className="grid">
        <div className={`col-12 ${globalStyles.headerContainer}`}>
          <h3 className={globalStyles.heading}>All Functions</h3>
          {userData?.roleId === 1 && <Button
            label="Add New Function"
            icon="pi pi-plus"
            onClick={()=>navigateToFunctionForm("new",0)}
            className={styles.addFunctionButton}
          />}
        </div>
      </div>
      <Table
        content={functionList}
        columnsData={Functioncolumns(navigateToFunctionForm,deleteFunction,featureFlags?.enableFTPAutomatedOrders)}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        header={(copyTable, isCopyEnable, data)=>FunctionHeader({filteredValue,filterOptionsList,searchedItem,onFilter,onSearch,styles, data, copyTable, isCopyEnable})}
        isCopyEnable={false}
      />
      {showConfirmDialog && (
        <ConfirmDialog
          visible={showConfirmDialog}
          onHide={cancel}
          message="Are you sure you wish to delete this function?Once deleted, you won't be able to see the orders for this function in the LogSummary page."
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          accept={accept}
        ></ConfirmDialog>
      )}
        <Toast ref={toast} position="center" />
    </>
  );
};

export default FunctionList;
