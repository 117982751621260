"use client";

import "../../../node_modules/primeflex/primeflex.css";
import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Menubar } from "primereact/menubar";

import styles from "../header-nav/headerNav.module.scss";
import LoginUserDataContext from "../../store/login";

const Navbar = (props) => {
  const [userData, setUserData] = useContext(LoginUserDataContext);
  const { instance, accounts } = useMsal();
  const [profileBg, setProfileBg] = useState("#ff0000");
  const heading = props?.heading;
  const items = [
    {
      label: "",
      icon: "pi pi-ellipsis-v",
      items: [
        {
          label: "Logout",
          icon: "",
          command: () => {
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",  // Route to the login page after logout
              account: accounts[0],
            });
          }
        },
        {
          label: "Help",
          icon: "",
          command: () => {
            const link = document.createElement('a');
            link.href = '/mojo-user-guide.pdf'; // Path to the PDF file in the public folder
            link.setAttribute('download', 'UserGuide.pdf'); // Optional: Set a custom download file name
        
            // Append the link to the body
            document.body.appendChild(link);
        
            // Programmatically click the link to trigger the download
            link.click();
        
            // Remove the link after triggering the download
            document.body.removeChild(link);
          }
        }
      ]
    }
  ];
  useEffect(() => {
    getRandomColor();
  }, []);

  const userFirstName = userData?.userName;
  const lastWord = userFirstName ? userFirstName.split(" ").pop() : "";
  const userLastName = lastWord ? lastWord.charAt(0) : "";

  const getRandomColor = () => {
    const Colors = [
      "#1a1a2e",
      "#16213e",
      "#0f3460",
      "#53354a",
      "#903749",
      "#e84545",
      "#662c91",
      "#2c003e",
      "#8d3da8",
      "#553e4e",
      "#7b6079",
      "#ff5200",
      "#ff7b54",
      "#da627d",
      "#a53860",
      "#0d0221",
      "#311d3f",
      "#6ea4bf",
      "#36454F",
      "#2F4F4F",
      "#191970",
      "#000080",
      "#006400",
      "#8B0000",
      "#556B2F",
      "#228B22",
      "#654321",
      "#3D2B1F",
      "#800000",
      "#4169E1",
      "#800020",
      "#4B0082",
      "#301934",
      "#614051",
      "#8B008B",
      "#9400D3",
      "#9932CC"
    ];
    let currentIndex = 0;
    return function () {
      const color = Colors[currentIndex];
      currentIndex = (currentIndex + 1) % Colors.length;
      setProfileBg(color);
    };
  };

  const downloadHelpGuid = () => {};
  return (
    <div className={`grid ${styles.navbarContainer}`}>
      <div className="col-12 lg:col-5">
        <h2 className={`${styles.darkCloud} ${styles.title}`}>{heading}</h2>
      </div>
      {userData?.userName && (
        <div className={`col-12 lg:col-7 ${styles.navbarProfileContainer}`}>
          <div
            className={`${styles.userProfileIcon} ${styles.userProfileInfo}`}
            style={{ backgroundColor: profileBg }}
          >
            {userFirstName?.[0][0] + userLastName}
          </div>
          <div className={styles.userProfileInfo}>
            <h6 className={`${styles.userName} ${styles.darkCloud}`}>
              {userData?.userName} | {userData?.roleName}
            </h6>
            <small className={`${styles.email} ${styles.darkCloud}`}>
              {userData?.emailAdrress}
            </small>
          </div>
          <div className={styles.userProfileInfo}>
            <Menubar model={items} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
