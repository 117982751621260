import {api} from "../api/api";
const getFunctionConfigurationDetails = async (id) => {
  try {
    const response = await api.get(
      `/api/Functions/EDIXMLMappings?functionConfigurationId=${id}`
    );
    return response.data?.result?.result || [];
  } catch (error) {
    if(error?.code?.trim() == "ERR_NETWORK"){
      throw new Error("It looks like we can't reach the server right now. Please try refreshing the page or check after sometime.");
    }
    else{
      throw new Error(error?.response?.data?.title);
    }
  }
};

export default getFunctionConfigurationDetails;
