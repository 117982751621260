import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Autocompetediv from "../../components/autocompletediv/autocompletediv"

const UserInfoForm = (props) => {
  const usersList = props?.masterData?.users;
  let usersListOptions = usersList?.map( user => user.emailAdrress);
  const editUserList  = props?.values?.users?.map( user => user.emailAdrress);
  let adminUsersListOptions = usersList?.map( user => user.emailAdrress);
  const editadminUserList  = props?.values?.adminUsers?.map( user => user.emailAdrress);
  const AIFDirectory = props?.values?.AIFDirectory ? props?.values?.AIFDirectory : props?.masterData?.outboundDirectory;
  const emailList = props?.values?.emailList;
  usersListOptions = usersListOptions?.filter(email => !editadminUserList?.includes(email));
  adminUsersListOptions = adminUsersListOptions?.filter( email => !editUserList?.includes(email));
  
  const dataSource = [
    { label: "D365", id: 0 },
    { label: "Axapta", id: 1 }
  ];
  const changeTab = (tabIndex) => {
    props?.tabChange(tabIndex)
  }
  const handleChange = (e, id) =>{
   let eventValue = [];
   e = e?.map(ele => ele.trim());
   if (id === 'users'){
    eventValue =  usersList.filter(user => e.includes(user.emailAdrress) && (editadminUserList === undefined || !editadminUserList.includes(user.emailAdrress)))
   }
   if (id === 'adminUsers'){
    eventValue =  usersList.filter(user => e.includes(user.emailAdrress) && (editUserList === undefined || !editUserList.includes(user.emailAdrress)))
   }

    const result = {target: {
      "name": null,
      "id": id,
      "value": eventValue
  }}
    props.changeEvent(result)
  }
  return (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Users</span>
          </label>
        </div>
       <Autocompetediv options={usersListOptions}
        values={editUserList? editUserList : []}
        id={"usersList"}
        disabled={props?.isReadonly}
        onBlur={(e) => handleChange(e, 'users' )} />
        {/* <InputTextarea
          rows={5}
          cols={30}
          className={`${props?.styles.formControl}`}
        /> */}
      </div>
      <div className="col-12 md:col-6">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Admin Users</span>
          </label>
        </div>
        <Autocompetediv options={adminUsersListOptions}
        values={editadminUserList? editadminUserList : []}
        id={"adminUsersList"}
        disabled={props?.isReadonly}
        onBlur={(e) => handleChange(e, 'adminUsers' )} />
      </div>
      <div className="col-12 md:col-4">
      <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Email List (,)</span>
          </label>
        </div>
        <InputTextarea
          value={emailList}
          id="emailList"
          name="emailList"
          rows={5}
          cols={30}
          disabled={props?.isReadonly}
          onChange={props?.changeEvent}
          style={{resize: 'none'}}
          className={`${props?.styles.formControl}`}
        />
        <div>
              {props?.errors.emailList &&
                props?.touched.emailList && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.emailList}
                  </span>
                )}
            </div>
      </div>
      {/* <div className="col-12 md:col-4">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Data Source</span>
          </label>
        </div>
        <div className="flex flex-wrap gap-3">
          {dataSource?.map((item) => {
            return (
              <div className="flex align-items-center justify-content-center">
                <RadioButton inputId={item?.id}  name="dataSource"  value={item}  checked={0 === item?.id}  className={`${props?.styles.formControl}`} />
                <label htmlFor={item?.id} className="ml-2">
                  {item?.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-12 md:col-4">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>
              AIF & Outbound Directory
            </span>
          </label>
        </div>
        <InputText id="AIFDirectory" disabled name="AIFDirectory" value={AIFDirectory} className={`${props?.styles.inputField} ${props?.styles.formControl}`} style={{background:"#E2E8F0"}}/>
      </div> */}
      <div className="col-12 text-right">
        <Button
          label="Previous"
          type="button"
          size="large"
          icon="pi pi-angle-left"
          iconPos="left"
          outlined
          onClick={()=>changeTab(0)}
          className={props?.styles.secondaryBtn}
        />
        <Button
          label="Next"
          type="button"
          size="large"
          icon="pi pi-angle-right"
          iconPos="right"
          outlined
          onClick={()=>changeTab(2)}
          className={props?.styles.secondaryBtn}
        />
      </div>
    </div>
  );
};

export default UserInfoForm;
