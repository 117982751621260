import {api} from "../api/api";

import JSZip from 'jszip';

const processData = async (formbody) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',  // Setting the correct content-type
      },
      responseType: 'arraybuffer',           // Receives response as binary data
      transformRequest: [(data) => data],    // Prevents any payload modification
    };

    // API call
    const response = await api.post('/api/ProcessLargeData', formbody, config);


    // Checks if response data is valid
    if (!response.data || response.data.byteLength === 0) {
      throw new Error('Received an empty ZIP file.');
    }

    // Loading the binary data (ArrayBuffer) into JSZip
    const zip = new JSZip();
    const unzippedData = await zip.loadAsync(response.data);

    // Extracting and using the contents inside the ZIP
    const extractedFiles = {};
    for (const filename of Object.keys(unzippedData.files)) {
      const fileContent = await unzippedData.files[filename].async('string');  // Assuming text/JSON content
      extractedFiles[filename] = JSON.parse(fileContent); // Parse JSON content
    }
    return extractedFiles['response.json'];
  }  catch (error) {
    let errorMessage = 'Failed to process data';
    if (error.response && error.response.data) {
      try {
        // Convert arraybuffer to string and parse as JSON
        const errorText = new TextDecoder().decode(error.response.data);
        const errorData = JSON.parse(errorText);
        errorMessage = errorData?.title || `Request failed with status ${error.response.status}`;
      } catch (parseError) {
        console.error('Error parsing error response:', parseError);
        errorMessage = `Request failed with status ${error.response.status}`;
      }
    } else if (error.message) {
      errorMessage = error.message;
    }

    throw new Error(errorMessage);
  }
};


// const processData = async (formbody) => {
//   try {
//     // 1. API Call to get the ZIP as binary data (ArrayBuffer)
//     const response = await api.post('/api/ProcessLargeData', formbody, {
//       headers: { 'Content-Type': 'application/json' },
//       responseType: 'blob', // Receive the ZIP file as Blob
//       transformRequest: [(data) => data], 
//     });

//     console.log('ZIP Blob received:', response.data);

//     // 2. Create a blob URL from the response
//     const zipBlob = new Blob([response.data], { type: 'application/zip' });
//     const downloadUrl = URL.createObjectURL(zipBlob);

//     // 3. Create an <a> element to trigger the download
//     const link = document.createElement('a');
//     link.href = downloadUrl;
//     link.download = 'download.zip'; // Specify the ZIP filename
//     document.body.appendChild(link);
//     link.click();

//     // 4. Cleanup: Revoke the object URL and remove the link element
//     URL.revokeObjectURL(downloadUrl);
//     document.body.removeChild(link);

//     console.log('ZIP file downloaded successfully.');
//   } catch (error) {
//     console.error('Error downloading ZIP file:', error);
//     throw new Error('Failed to download ZIP file.');
//   }
// };

export default processData;

