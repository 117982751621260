import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import fetchXMLFile from "../../services/xmlfile";
import ProcessedData from "../../components/processed-data/processed-data";
import { Editor } from "primereact/editor";
import * as constants from "../../constants/constants";

const Table = (props) => {
  const {
    content: data,
    columnsData: columns,
    tableStyle,
    paginatorTemplate,
    paginator,
    scrollable,
    rows,
    footer,
    header,
    rowsPerPageOptions,
    emptyMessage,
    currentPageReportTemplate,
    rowExpansionColumnsData,
    rowExpansionLogSummaryColumnsErrorsData,
    styles
  } = props;
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [xmlfile, setXmlfile] = useState("");
  const [lineitemsData, setLineItemsData] = useState("");
  const [showLineItems, setShowLineItems] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const errorPosition = "center";

  const showModal = async (rawData) => {
    try {
      setXmlfile("");
      let result = "";
      result = await fetchXMLFile({ blobName: rawData?.fileName });
      setXmlfile(result);
      setVisible(true);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  const lineItems = (rowData) => {
    setShowLineItems(true)
    setLineItemsData(rowData);
  };

  const copyxmlfile = () => {
    navigator.clipboard
      .writeText(xmlfile)
      .then((e) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "XML File Copied Successfully",
          life: 4000
        });
        setVisible(false);
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: e.message,
          life: 4000
        });
        setVisible(false);
      });
  };

  const allowExpansion = (rowData) => {
    return (
      rowData?.logSummaryFileDTOs?.length > 0 ||
      rowData?.unprocessedErrors?.length > 0
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-1 pl-5 ml-5" style={{background:'white'}}>
        <h6 style={{ fontSize: "20px", fontWeight: "400", margin: "1px", color:"#004F74",border: "1px solid #E9ECEF", background: "rgba(15, 164, 233, 0.10)" }}>
          Items Information
        </h6>
        <DataTable value={data.logSummaryFileDTOs}  rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}>
          {rowExpansionColumnsData?.map((col, i) =>
            col?.columnType?.toLocaleLowerCase() === "fun" ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth,fontSize:'14px' }}
                headerStyle={{ minWidth: col.minWidth }}
                body={(e) => col.columnDefination(e, showModal, lineItems)}
              />
            ) : (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ width: col?.minWidth, fontSize:'14px'}}
                headerStyle={{ width: col.minWidth }}
                body={
                  col?.field?.toLocaleLowerCase() === "errors"
                    ? props?.createOrderErrorSplit
                    : null
                }
              />
            )
          )}
        </DataTable>
        {data.unprocessedErrors?.length > 0 && <>
        <h6 style={{ fontSize: "20px", fontWeight: "400", margin: "1px", color:"#A52121",border: "1px solid #E9ECEF", background: "#FEF2F2" }}>
          Unprocessed Error Items
        </h6>
        <DataTable value={data.unprocessedErrors}  rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}>
          {rowExpansionLogSummaryColumnsErrorsData?.map((col, i) =>
            col?.columnType?.toLocaleLowerCase() === "fun" ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth,fontSize:'14px' }}
                headerStyle={{ minWidth: col.minWidth }}
               
                body={(e) =>
                  col.columnDefination(e, setShowErrorDialog, setErrorList)
                }
              />
            ) : (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth,fontSize:'14px' }}
                headerStyle={{ minWidth: col.minWidth }}
                body={
                  col?.field?.toLocaleLowerCase() === "errors"
                    ? props?.createOrderErrorSplit
                    : null
                }
              />
            )
          )}
        </DataTable></>}
      </div>
    );
  };
  const footerContent = (
    <div className="col-12 grid">
      <div className="col-12 md:col-2">
        <Button
          label="OK"
          size="medium"
          className={styles.primaryBtn}
          onClick={() => setVisible(false)}
          autoFocus
        />
      </div>
      <div className="col-12 md:col-2 md:col-offset-8">
        <Button
          label="Copy"
          size="medium"
          className={styles.copyBtn}
          onClick={() => copyxmlfile()}
          autoFocus
        />
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        header="File Details"
        visible={visible}
        style={{ width: "50vw" }}
        footer={footerContent}
        onHide={() => setVisible(false)}
      >
        <pre className="mb-5">{xmlfile}</pre>
      </Dialog>
      <Dialog
        header="Line Items"
        visible={showLineItems}
        style={{ width: "80vw" }}
        onHide={() => setShowLineItems(false)}
      >
        <ProcessedData processedData={lineitemsData} fromscreen="autolog"/>
      </Dialog>
      {showErrorDialog && (
        <Dialog
          header="Error list"
          visible={showErrorDialog}
          style={{ width: "50vw" }}
          position={errorPosition}
          onHide={() => setShowErrorDialog(false)}
        >
          <ul className={styles.xmlList}>
            {errorList?.map((item) => {
              return <li className={styles.xmlListItem}>{item}</li>;
            })}
          </ul>
        </Dialog>
      )}
      <Toast ref={toast} position="top-center" />
      <DataTable
        value={data}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        scrollable={scrollable}
        className={`${tableStyle.dataTable}`}
        rows={rows}
        footer={footer}
        header={header}
        rowsPerPageOptions={rowsPerPageOptions}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
      >
        <Column expander={allowExpansion} style={{ width: "10px" }} />
        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            style={{ minWidth: col?.minWidth }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col?.columnType?.toLocaleLowerCase() === "fun"
                ? col.columnDefination
                : col?.field?.toLocaleLowerCase() === "errors"
                ? props?.createOrderErrorSplit
                : null
            }
          />
        ))}
      </DataTable>
    </>
  );
};

export default Table;
