"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionTypes from "../../store/actions";
import * as actionCreators from "../../store/action-creators";
import { useFormik } from "formik";
import * as YUP from "yup";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog"; // For <ConfirmDialog /> component
import { Toast } from "primereact/toast";
import processData from "../../services/processdata";
import Spinner from "../../components/spinner/spinner";
import ProcessedData from "../../components/processed-data/processed-data";
import "../../globals.css";
import styles from "./create-order-form.module.scss";
import LoginUserDataContext from "../../store/login";
import {sortData} from "../../constants/dataSort"
import {  unstable_usePrompt } from "react-router-dom";
import { appInsights } from '../../AppInsights';

const initialValue = {
  region: null,
  function: null,
  poNumber: "",
  file: null,
  pasteContent: ""
};

const CreateOrderForm = () => {
  appInsights.trackPageView({ name: 'Create order screen visited' });
  const fileInputRef = useRef(null);
  const [isFileRequired, setIsFileRequired] = useState(true);
  const [fileFormatTypes, setFileFormatTypes] = useState(
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
  );
  const poNumberRef = useRef("");
  const [operationPerformed, setOperationPerformed] = useState(null);
  const inputRef = useRef(null);
  const toast = useRef(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [restrictNavigation, setRestrictNavigation] = useState(false);
  const [pasteError, setPasteError] = useState("");
  const [showPO, setShowPO] = useState(true);
  const [excelUploaded, setExcelUploaded] = useState(false);
  const [excelUploadedFileName, setExcelUploadedFileName] = useState("");
  const [excelPasted, setExcelPasted] = useState(false);
  const [fileContentUploaded, setFileContentUploaded] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [processedDataStarted, setProcessedDataStarted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [userData, setUserData] = useContext(LoginUserDataContext);
  const [showWarningDays, setShowWarningDays] = useState(false);
  const [warningDaysContent, setWarningDaysContent] = useState("00/00/00");
  const [fileFormat, setFileFormat] = useState("");

  const dispatch = useDispatch();
  const { getRegions, getFunctions } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const regions = useSelector((state) => {
    const data = state.orderReducer.regions;
    const sortedData = sortData('regionNameAbbr', data)
    return sortedData;
  });

  const validationSchema = YUP.object().shape({
    region: YUP.object().nullable().required("Region is required"),
    function: YUP.object().nullable().required("Function is required"),
    poNumber: showPO
      ? YUP.string().required("PO Number is required").min(5).max(50)
      : YUP.string().nullable().min(5).max(50),
    file: isFileRequired
      ? YUP.string().nullable().required("File is required")
      : YUP.string().nullable(),
    pasteContent: isFileRequired
      ? YUP.string().nullable()
      : YUP.string().required("Excel data is required")
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitProcessData(values);
    }
  });

  const clearForm = () => {
    try{
      formik.resetForm();
      setProcessedData([]);
      clearSelectedFile();
      formik.setFieldValue("region", regions[0]);
      fetchFunctions(regions[0]?.regionId);
      setRestrictNavigation(false);
    }
    catch(error){
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while clearing create order form",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };
  const functionsData = useSelector((state) => {
    const data =  state.orderReducer?.functions?.[formik?.values?.region?.regionId]
      ? state.orderReducer?.functions?.[formik?.values?.region?.regionId]
      : [];

    const sortedData = sortData('functionName', data)
    return sortedData;
  });

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (regions.length > 0 && regions[0] === "error") {
      setShowSpinner(false);
      dispatch({
        type: actionTypes.GET_REGIONS,
        value: []
      });
    } else if (regions.length > 0) {
      formik.setFieldValue("region", regions[0]);
      fetchFunctions(regions[0]?.regionId);
    }
    else{
      setShowSpinner(false);
    }
  }, [regions]);

  useEffect(() => {
    if (functionsData.length > 0) {
      setShowSpinner(false)
      formik.setFieldValue("function", functionsData[0]);
      formik.setFieldValue("poNumber", "");
      setFileFormatTypes(functionsData[0]?.fileTypes);
      setFileFormat(functionsData[0]?.fileFormat);
      if (
        functionsData[0].poMode &&
        (functionsData[0].poMode?.toLocaleLowerCase() === "user entered" ||
          functionsData[0].poMode?.toLocaleLowerCase() === "userenterseqed")
      ) {
        setShowPO(true);
        setShowSpinner(false)
      } else {
        setShowPO(false);
        setShowSpinner(false)
      }
    }
    else{
      setShowSpinner(false)
    }
  }, [functionsData]);

  const submitProcessData = async (formbody) => {
    try {
      const formData = new FormData();
      formData.append("RegionId", formbody.region.regionId);
      formData.append("FunctionId", formbody.function.functionId);
      formData.append("PastedContent", formbody.pasteContent);
      formData.append("File", formbody.file);
      formData.append("PONumber", formbody.poNumber?.trim());
      setProcessedDataStarted(true);
      const result = await processData(formData);
      if (result.length > 0) {
        if (result[0]?.warningDate || result[0]?.warningDate?.trim() === "") {
          const data = result[0]?.warningDate?.trim() != ""? result[0]?.warningDate : '00/00/00'
          setShowWarningDays(true);
          setWarningDaysContent(data);
        }
      }
      setProcessedData(result);
      setPasteError("");
      setProcessedDataStarted(false);
      setRestrictNavigation(true)
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Data Processed Successfully",
        life: 4000
      });
    } catch (error) {
      setProcessedDataStarted(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while submitting create order form",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const fetchFunctions = async (regionId) => {
    try {
      setShowSpinner(true);
      const params = {
        regionId: regionId,
        statusId: 41,
        isLogSummary: false,
        isAutoLogSummary: false
      };
      getFunctions({ params });
    } catch (error) {
      setShowSpinner(false);
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while Fetching Functions list in create order form",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const handleRegionChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("region", value);
    formik.setFieldValue("function", null);
    formik.handleChange(event);
    fetchFunctions(value?.regionId);
    clearSelectedFile();
  };

  const handleFunctionChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("function", value);
    formik.setFieldValue("poNumber", "");
    setFileFormatTypes(value?.fileTypes);
    setFileFormat(value?.fileFormat);
    formik.handleChange(event);
    clearSelectedFile();
    if (
      value.poMode &&
      (value.poMode?.toLocaleLowerCase() === "user entered" ||
        value.poMode?.toLocaleLowerCase() === "userenterseqed")
    ) {
      setShowPO(true);
    } else {
      setShowPO(false);
    }
  };
  const browseFiles = () => {
    setOperationPerformed("upload")
    if (processedData.length > 0) {
      setShowConfirmDialog(true);
    } else {
      setShowConfirmDialog(false);
      fileInputRef.current.click();
      setPasteError("");
    }
  };
  const onFileSelected = (event) => {
    setFileContentUploaded(true);
    setExcelUploaded(true);
    setExcelPasted(false);
    setExcelUploadedFileName(event.target.files[0]?.name);
    setIsFileRequired(true);
    formik.setFieldValue("file", event.target.files[0]);
    setProcessedData([]);
    setRestrictNavigation(false);
    setPasteError("");
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    // setFiles(event.dataTransfer.files)
    if (event.dataTransfer.files?.length !== 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select single file.",
        life: 4000
      });
      return;
    }
    const fileName = event.dataTransfer.files[0]?.name;
    const fileType = fileName.substring(fileName.lastIndexOf("."));

    if (fileFormatTypes.includes(fileType?.toLocaleLowerCase())) {
      setFileContentUploaded(true);
      setExcelUploaded(true);
      setExcelPasted(false);
      setExcelUploadedFileName(fileName);
      setIsFileRequired(true);
      formik.setFieldValue("file", event.dataTransfer.files[0]);
      setProcessedData([]);
      setRestrictNavigation(false);
      setPasteError("");
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Please select ${fileFormatTypes} file format.`,
        life: 4000
      });
    }
  };

  const isExcelData = (data) => {
    if (data.endsWith("\n")) {
      data = data.slice(0, -1);
    }
    if (!data.includes("\t") && !data.includes(",") && !data.includes(";")) {
      return false;
    }
    // Split pasted data into rows based on newline character
    const rows = data.split("\n")?.map((row) => row);

    // Check if there are at least two rows
    if (rows.length < 2) {
      return false;
    }

    const columnCounts = data.includes("\t") ? rows?.map((row) => row.split("\t").length) : data.includes(",") ? rows?.map((row) => row.split(",").length) : data.includes(";") ? rows?.map((row) => row.split(";").length) : "";
   // const columnCounts = rows?.map((row) => row.split("\t").length)
    const uniqueColumnCounts = new Set(columnCounts);
    return uniqueColumnCounts.size >= 1;
  };

  const handlePasteButtonClick = async () => {
    setIsFileRequired(false);
    setExcelUploaded(false);
    setExcelPasted(true);
    setOperationPerformed("paste")
    setExcelUploadedFileName("");
    if (processedData.length > 0) {
      setShowConfirmDialog(true);
    } else {
      setShowConfirmDialog(false);
      if (navigator.clipboard && navigator.clipboard.readText) {
        try {
          inputRef.current.focus();
          const text = await navigator.clipboard.readText();
          if (isExcelData(text)) {
            setFileContentUploaded(true);
            formik.setFieldValue("pasteContent", text);
            setProcessedData([]);
            setRestrictNavigation(false);
            setPasteError("");
            return;
          } else {
            clearSelectedFile();
            setPasteError("Please paste the valid content.");
            return;
            // Handle non-Excel data here
          }
        } catch (error) {
          console.warn("navigator.clipboard failed:", error);
          appInsights.trackException({
            exception: error,
            properties: {
              additionalInfo: "Exception while pasting file in create order screen",
            },
            measurements: {
              severityLevel: 2 
            }
          });
        }
      }
    }
  };

  const clearSelectedFile = () => {
    setIsFileRequired(true);
    formik.setFieldValue("file", null);
    formik.setFieldValue("pasteContent", "");
    formik.setFieldValue("poNumber", "");
    if (excelUploaded) {
      formik.handleChange({
        target: {
          name: "file", // Name of the field you want to update
          value: null // New value you want to set (in this case, null)
        }
      });
    } else {
      formik.handleChange({
        target: {
          name: "pasteContent", // Name of the field you want to update
          value: "" // New value you want to set (in this case, null)
        }
      });
    }
    setFileContentUploaded(false);
    setExcelPasted(false);
    setExcelUploaded(false);
    setExcelUploadedFileName("");
    setPasteError("");
  };

  const accept =  () => {
    try{
      setProcessedData(()=>{
        const newData = [];
        return newData;
      });
      setRestrictNavigation(false);
      autoOpenOrautoPasteContent()
    }
    catch(error){
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error clearing the processed data content",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };
  const autoOpenOrautoPasteContent = async () =>{
    try{
      if(operationPerformed == "upload" ){
        setShowConfirmDialog(false);
        fileInputRef.current.click();
        setPasteError("");
      }
      else if(operationPerformed == "paste"){
        setShowConfirmDialog(false);
        if (navigator.clipboard && navigator.clipboard.readText) {
          try {
            inputRef.current.focus();
            const text = await navigator.clipboard.readText();
            if (isExcelData(text)) {
              setFileContentUploaded(true);
              formik.setFieldValue("pasteContent", text);
              setProcessedData([]);
              setRestrictNavigation(false);
              setPasteError("");
              return;
            } else {
              clearSelectedFile();
              setPasteError("Please paste the valid content.");
              return;
              // Handle non-Excel data here
            }
          } catch (error) {
            console.warn("navigator.clipboard failed:", error);
            appInsights.trackException({
              exception: error,
              properties: {
                additionalInfo: "Exception while pasting file in create order screen",
              },
              measurements: {
                severityLevel: 2 
              }
            });
          }
        }
      }
    }
    catch(error){
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while auto Pasting or auto file upload browser open",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  }
  const handleConfirmation = () => {
    if (processedData.length > 0) {
      setShowConfirmDialog(true);
    } else {
      setShowConfirmDialog(false);
    }
  };
  unstable_usePrompt({
    message: "Changes you made may not be saved. Are you sure you want to proceed?",
    when: processedData.length > 0 && restrictNavigation
  });
  return (
    <>
      {showSpinner && <Spinner />}
      <form onSubmit={formik.handleSubmit}>
        {processedDataStarted && <Spinner />}
        <div className="grid createOrderContainer">
          <div className="col-12">
            <div className="grid">
              <div
                className={`col-12 lg:col-4 lg:col-offset-4 ${styles.dropdownContainer}`}
              >
                <label htmlFor="region">
                  <span className="star">*</span>
                  <span className={styles.formLabel}>Region </span>
                </label>
                <Dropdown
                  value={formik.values.region}
                  onChange={handleRegionChange}
                  options={regions}
                  filter
                  optionLabel="regionNameAbbr"
                  placeholder="Select a Region"
                  className={styles.dropdown}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.region && formik.touched.region && (
                  <small className={styles.errorMsg}>
                    {formik.errors.region}
                  </small>
                )}
              </div>
              <div className={`col-12 lg:col-4 ${styles.dropdownContainer}`}>
                <label htmlFor="function">
                  <span className="star">*</span>
                  <span className={styles.formLabel}>Function</span>
                </label>
                <Dropdown
                  value={formik.values.function}
                  onChange={handleFunctionChange}
                  filter
                  options={functionsData}
                  optionLabel="functionName"
                  placeholder="Select a Function"
                  className={styles.dropdown}
                />
                {formik.errors.function && formik.touched.function && (
                  <small className={styles.errorMsg}>
                    {formik.errors.function}
                  </small>
                )}
              </div>
            </div>
          </div>
          {/* <div className={`col-12 ${styles.ashFont}`}style={{textAlign: 'right',
    padding: '0px',
    paddingRight: '28px', margin:'0px'}}>
                  {fileFormat}
          </div> */}
          <div className="col-12">
            <div
              className={styles.fileUploadContainer}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className={styles.fileUploadBtnContainer}>
                {!fileContentUploaded && (
                  <div className={styles.btnBlock}>
                    <input
                      type="file"
                      id="fileInput"
                      ref={fileInputRef}
                      value={formik.values.file}
                      onChange={onFileSelected}
                      accept={fileFormatTypes}
                      style={{ display: "none" }}
                    />
                    <Button
                      label="Upload"
                      type="button"
                      size="small"
                      icon="pi pi-upload"
                      onClick={browseFiles}
                      className={styles.primaryBtn}
                    />
                  </div>
                )}
                {!fileContentUploaded && (
                  <div>
                    <p className={styles.ashFont}>Or</p>
                  </div>
                )}
                <div
                  className={`${styles.btnBlock} ${
                    fileContentUploaded && excelPasted
                      ? styles.textAreaBlock
                      : ""
                  }`}
                >
                  {fileContentUploaded && excelPasted && (
                    <textarea
                      ref={inputRef}
                      id="pasteContent"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.pasteContent}
                      className={styles.textAreaContainer}
                    />
                  )}

                  {!fileContentUploaded && (
                    <div>
                      <textarea
                        ref={inputRef}
                        type="text"
                        value={formik.values.pasteContent}
                        readOnly
                        className={styles.textAreaContainer}
                        style={{ display: "none" }}
                      />
                      <Button
                        label="Paste"
                        icon="pi pi-pencil"
                        size="small"
                        type="button"
                        onClick={handlePasteButtonClick}
                        outlined
                        className={styles.secondaryBtn}
                      />
                    </div>
                  )}
                </div>
                {fileContentUploaded && excelUploaded && (
                  <span className={styles.uploadedExcel}>
                    <i className="pi pi-info-circle" />
                    <p className={styles.uploadedExcelTitle}>
                      {excelUploadedFileName + " added."}
                    </p>
                    <i
                      className="pi pi-times-circle"
                      onClick={clearSelectedFile}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                )}
              </div>
              {!fileContentUploaded && (
                <div>
                  <p className={styles.ashFont}>
                    Drag & Drop, Upload or Paste Data
                  </p>
                 {fileFormat != "" && <small className={styles.ashFont}>
                    Upload  {fileFormat}
                  </small>}
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className={`grid ${styles.creatFormButnContainer}`}>
              <div
                className={`col-12 lg:col-5 ${styles.clearButtonAndErrorContainer}`}
              >
                {fileContentUploaded && (
                  <Button
                    label="Clear"
                    onClick={clearSelectedFile}
                    type="button"
                    size="small"
                    severity="danger"
                    outlined
                  />
                )}
                {pasteError.trim() !== "" && (
                  <div
                    className={` ${styles.errorMsg} ${styles.errorContainer}`}
                  >
                    <span>{pasteError}</span>
                  </div>
                )}
              </div>

              <div className={`col-12 lg:col-7 ${styles.alignRight}`}>
                <div className="grid">
                  <div className={`col-12 lg:col-8 Ponumber`}>
                    {showPO && (
                      <>
                        <label htmlFor="poNumber">
                          <span className={styles.formLabel}>
                            <span className="star">*</span>PO Number
                          </span>
                        </label>
                        <InputText
                          id="poNumber"
                          name="poNumber"
                          value={formik.values.poNumber}
                          ref={poNumberRef}
                          className={styles.inputField}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.poNumber && formik.touched.poNumber && (
                          <div className={styles.errorMsg}>
                            {formik.errors.poNumber}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-12 lg:col-4 processData">
                    <Button
                      label="Process Data"
                      type="submit"
                      size="small"
                      className={styles.primaryBtn}
                      disabled={!formik.isValid}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showConfirmDialog && (
        <ConfirmDialog
          visible={showConfirmDialog}
          onHide={() => setShowConfirmDialog(false)}
          message="Changes you made may not be saved. Are you sure you want to proceed?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          accept={accept}
        ></ConfirmDialog>
      )}
      {showWarningDays && (
        <Dialog
          header="Information"
          visible={showWarningDays}
          style={{ width: "50vw", background:'rgba(255, 0, 0, 0.09)' }}
          className="xmlListDialog"
          onHide={() => setShowWarningDays(false)}
        >
          <div className="grid" style={{ textAlign: "center" }}>
            {/* <div className="col-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 512 512"><path fill="#faba15" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
            </div> */}
            <div className="col-12" style={{display:"flex", flexDirection:"column", justifyContent:'center'}}>
              <span>Orders will drop into D365 for the requested ship date of: </span>
              <span>
              <b>{warningDaysContent}</b>
              </span>
              <span>Please verify the date.</span>
            </div>
          </div>
          <div className="grid" style={{ paddingTop: "12px", justifyContent:"center" }}>
            <div className="col-2">
              <Button
                label="OK"
                type="button"
                onClick={() => setShowWarningDays(false)}
                className={styles.primaryBtn}
              />
            </div>
          </div>
        </Dialog>
      )}
      <Toast ref={toast} position="center" />
      <ProcessedData
        processedData={processedData}
        destination={formik.values.region?.destination}
        clearForm={clearForm}
        handleConfirmation={handleConfirmation}
        setRestrictNavigation={setRestrictNavigation}
      />
    </>
  );
};

export default CreateOrderForm;
