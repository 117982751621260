import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import {sortData} from "../../constants/dataSort";
import { MultiSelect } from 'primereact/multiselect';

const GeneralInfoForm = (props) => {
      const [customerList, setCustomerList] = useState([]);
      const [timeZoneList, setTimeZoneList] = useState([]);
      const regionsList = sortData('regionNameAbbr',props?.masterData?.regions) ;
      const pomodesList = sortData('poMode',props?.masterData?.poModes);
      const shipReqList = [{label:"Select", id:1},{label:"Current Date", id:2}]
      const mapToUseList = sortData('fileFormat',props?.masterData?.fileFormats);
      const customersList = sortData('customerName',props?.masterData?.customers);
      
      const changeTab = () => {
        props?.tabChange(1)
      }
     const handleFilterCustomers = (event) =>{
      const { value } = event.target;
      props.setFieldValue("region", value);
      props.setFieldValue("customer", null);
      const filteredCustomers = customersList?.filter((customer) => customer?.regionId == value?.regionId);
      setCustomerList(sortData('customerName',filteredCustomers))
     }

     const showSupportingField = (event) =>{
      const { value } = event.target;
      props.setFieldValue("poMode", value);
      if(value?.poModeId === 6){
        props.setFieldValue("customvalue", null)
        props?.setShowPONumberField(true)
        props?.setShowCustomValueField(false);
      }
      else if(value?.poModeId === 7) {
        props.setFieldValue("poNum", null)
        props?.setShowPONumberField(false)
        props?.setShowCustomValueField(true);
      }
      else{
        props.setFieldValue("poNum", null)
        props.setFieldValue("customvalue", null)
        props?.setShowPONumberField(false)
        props?.setShowCustomValueField(false);
      }
     }

     const handleshipReqDate = (event) =>{
      const { value } = event.target;
      props.setFieldValue("shipReqDate", value);
      if(value?.id == 2){
        props?.setIsTimeZoneMandatory(true)
      }
      else{
        props?.setIsTimeZoneMandatory(false)
      }
     }

     useEffect(()=>{
      const filteredCustomers = customersList?.filter((customer) => customer?.regionId == props?.values?.region?.regionId);
      setCustomerList(sortData('customerName',filteredCustomers))
     },[props?.values])

     useEffect(()=>{
      const data = props?.masterData?.timezones;
      if(data?.length > 0){
        const modifiedData = data?.map((item)=>{
           item.timeZoneLabel = item?.timezoneName + '(' + item?.offsetFromUtc + ')'
           return item
        })
        const result = sortData('timeZoneLabel',modifiedData)
        setTimeZoneList(result)
        if(props?.values?.timezone == null){
          props?.setFieldValue("timezone", result[0])
        }     
      }
     },[props?.masterData?.timezones])
      
    
  return (
    <div className="grid">
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Region</span>
            <span className="star">*</span>
          </label>
        </div>
        <Dropdown
          id="region"
          filter
          disabled={props?.isReadonly}
          placeholder="Select a Region"
          value={props?.values?.region}
          options={regionsList}
          optionLabel="regionNameAbbr"
          onChange={(e) => handleFilterCustomers(e)}
          className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.region && props?.touched.region && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.region}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="customer">
            <span className={props?.styles.formLabel}>Customer</span>
            <span className="star">*</span>
          </label>
        </div>
        <MultiSelect
          id="customer"
          filter
          disabled={props?.isReadonly}
          maxSelectedLabels={2}
          placeholder="Select Customers"
          value={props?.values?.customer}
          options={customerList}
          optionLabel="customerName"
          onChange={props?.changeEvent}
          className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.customer && props?.touched.customer && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.customer}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="function">
            <span className={props?.styles.formLabel}>Function</span>
            <span className="star">*</span>
          </label>
        </div>
        <InputText
          id="function"
          disabled={props?.isReadonly}
          name="function"
          value={props?.values?.function}
          onChange={props?.changeEvent}
          className={`${props?.styles.inputField} ${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.function && props?.touched.function && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.function}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="mapToUse">
            <span className={props?.styles.formLabel}>Map to Use</span>
            <span className="star">*</span>
          </label>
        </div>
        <Dropdown
          placeholder="Select a Map"
          filter
          disabled={props?.isReadonly}
          id="mapToUse"
          value={props?.values?.mapToUse}
          options={mapToUseList}
          optionLabel="fileFormat"
          onChange={props?.changeEvent}
          className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.mapToUse && props?.touched.mapToUse && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors?.mapToUse}
            </span>
          )}
        </div>
      </div>
      {(props?.showPoNumberField && !props?.showCustomValueField) && (
        <div className={`col-12 md:col-6 ${props?.styles?.groupFields}`}>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className={props?.styles.formLabelContainer}>
                <label htmlFor="poMode">
                  <span className={props?.styles.formLabel}>PO Mode</span>
                  <span className="star">*</span>
                </label>
              </div>
              <Dropdown
                id="poMode"
                filter
                disabled={props?.isReadonly}
                value={props?.values?.poMode}
                options={pomodesList}
                optionLabel="poMode"
                onChange={(e) => showSupportingField(e)}
                className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
                placeholder="Select a PO Mode"
              />
              <div>
                {props?.errors.poMode && props?.touched.poMode && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.poMode}
                  </span>
                )}
              </div>
            </div>
            {props?.showPoNumberField && (
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="poNum">
                    <span className={props?.styles.formLabel}>PO Number</span>
                    <span className="star">*</span>
                  </label>
                </div>
                <InputText
                  id="poNum"
                  disabled={props?.isReadonly}
                  name="poNum"
                  value={props?.values?.poNum}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.poNum && props?.touched.poNum && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.poNum}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {(!props?.showPoNumberField && props?.showCustomValueField) && (
        <div className={`col-12 md:col-6 ${props?.styles?.groupFields}`}>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className={props?.styles.formLabelContainer}>
                <label htmlFor="poMode">
                  <span className={props?.styles.formLabel}>PO Mode</span>
                  <span className="star">*</span>
                </label>
              </div>
              <Dropdown
                id="poMode"
                filter
                disabled={props?.isReadonly}
                value={props?.values?.poMode}
                options={pomodesList}
                optionLabel="poMode"
                onChange={(e) => showSupportingField(e)}
                className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
                placeholder="Select a PO Mode"
              />
              <div>
                {props?.errors.poMode && props?.touched.poMode && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.poMode}
                  </span>
                )}
              </div>
            </div>
            {props?.showCustomValueField && (
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="customvalue">
                    <span className={props?.styles.formLabel}>Custom Value</span>
                    <span className="star">*</span>
                  </label>
                </div>
                <InputText
                  id="customvalue"
                  disabled={props?.isReadonly}
                  name="customvalue"
                  value={props?.values?.customvalue}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.customvalue && props?.touched.customvalue && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.customvalue}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {(!props?.showPoNumberField && !props?.showCustomValueField) &&(
        <div className="col-12 md:col-3">
          <div className={props?.styles.formLabelContainer}>
            <label htmlFor="poMode">
              <span className={props?.styles.formLabel}>PO Mode</span>
              <span className="star">*</span>
            </label>
          </div>
          <Dropdown
            id="poMode"
            filter
            disabled={props?.isReadonly}
            value={props?.values?.poMode}
            options={pomodesList}
            optionLabel="poMode"
            onChange={(e) => showSupportingField(e)}
            className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
            placeholder="Select a PO Mode"
          />
          <div>
            {props?.errors.poMode && props?.touched.poMode && (
              <span className={props?.styles?.errorMsg}>
                {props?.errors.poMode}
              </span>
            )}
          </div>
        </div>
      )}
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="shipReqDate">
            <span className={props?.styles.formLabel}>Ship Request Date</span>
          </label>
        </div>
        <Dropdown
          id="shipReqDate"
          filter
          disabled={props?.isReadonly}
          value={props?.values?.shipReqDate}
          options={shipReqList}
          optionLabel="label"
          onChange={(e) => handleshipReqDate(e)}
          className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
          placeholder="Select"
        />
        <div>
          {props?.errors.shipReqDate && props?.touched.shipReqDate && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.shipReqDate}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="timezone">
            <span className={props?.styles.formLabel}>Time Zone</span>
            {props?.isTimeZoneMandatory && <span className="star">*</span>}
          </label>
        </div>
        <Dropdown
          id="timezone"
          filter
          disabled={props?.isReadonly}
          value={props?.values?.timezone}
          options={timeZoneList}
          optionLabel="timeZoneLabel"
          onChange={props?.changeEvent}
          className={`${props?.styles?.dropdown} ${props?.styles?.formControl}`}
          placeholder="Select"
        />
        <div>
          {props?.errors.timezone && props?.touched.timezone && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.timezone}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="maxQuantity">
            <span className={props?.styles.formLabel}>
              Maximum Item Quantity
            </span>
          </label>
        </div>
        <InputNumber
          id="maxQuantity"
          value={props?.values.maxQuantity}
          onValueChange={props?.changeEvent}
          onBlur={props?.blurEvent}
          disabled={props?.isReadonly}
          showButtons
          min={1}
          max={2147483647}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.maxQuantity && props?.touched.maxQuantity && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.maxQuantity}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="region">
            <span className={props?.styles.formLabel}>Offset Ship Days</span>
          </label>
        </div>
        <InputNumber
          id="offShipdays"
          value={props?.values.offShipdays}
          onValueChange={props?.changeEvent}
          onBlur={props?.blurEvent}
          disabled={props?.isReadonly}
          showButtons
          min={-32768}
          max={32767}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.offShipdays && props?.touched.offShipdays && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.offShipdays}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="allowedPasteDays">
            <span className={props?.styles.formLabel}>Allowed Past Days</span>
          </label>
        </div>
        <InputNumber
          id="allowedPasteDays"
          value={props?.values.allowedPasteDays}
          onValueChange={props?.changeEvent}
          onBlur={props?.blurEvent}
          disabled={props?.isReadonly}
          showButtons
          min={0}
          max={32767}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.allowedPasteDays &&
            props?.touched.allowedPasteDays && (
              <span className={props?.styles?.errorMsg}>
                {props?.errors.allowedPasteDays}
              </span>
            )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="warningDays">
            <span className={props?.styles.formLabel}>
              Warning Days is Today Plus
            </span>
          </label>
        </div>
        <InputNumber
          id="warningDays"
          value={props?.values.warningDays}
          onValueChange={props?.changeEvent}
          disabled={props?.isReadonly}
          onBlur={props?.blurEvent}
          showButtons
          min={-32768}
          max={32767}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.warningDays && props?.touched.warningDays && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.warningDays}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="tolerance">
            <span className={props?.styles.formLabel}>Tolerance %</span>
          </label>
        </div>
        <InputNumber
          id="tolerance"
          value={props?.values.tolerance}
          onValueChange={props?.changeEvent}
          disabled={props?.isReadonly}
          onBlur={props?.blurEvent}
          showButtons
          min={0}
          max={100}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.tolerance && props?.touched.tolerance && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.tolerance}
            </span>
          )}
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="offsetreceiptDays">
            <span className={props?.styles.formLabel}>Offset Receipt Days</span>
          </label>
        </div>
        <InputNumber
          id="offsetreceiptDays"
          value={props?.values.offsetreceiptDays}
          onValueChange={props?.changeEvent}
          disabled={props?.isReadonly}
          onBlur={props?.blurEvent}
          showButtons
          min={-32768}
          max={32767}
          className={`${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.offsetreceiptDays &&
            props?.touched.offsetreceiptDays && (
              <span className={props?.styles?.errorMsg}>
                {props?.errors.offsetreceiptDays}
              </span>
            )}
        </div>
      </div>
      <div className="col-12">
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="ediextraComment">
                <span className={props?.styles.formLabel}>
                  EDI Extra Comment
                </span>
              </label>
            </div>
            <InputTextarea
              autoResize
              rows={5}
              cols={30}
              disabled={props?.isReadonly}
              id="ediextraComment"
              value={props?.values.ediextraComment}
              onChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              className={`${props?.styles.formControl}`}
            />
            <div>
              {props?.errors.ediextraComment &&
                props?.touched.ediextraComment && (
                  <span className={props?.styles?.errorMsg}>
                    EDI Extra Comment must be at most 100 characters
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="region">
                <span className={props?.styles.formLabel}>AIF XML</span>
              </label>
            </div>
            <div
              className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
            >
              <Checkbox
                id="includeRoutes"
                name="includeRoutes"
                disabled={props?.isReadonly}
                checked={props?.values.includeRoutes}
                onChange={props?.changeEvent}
                onBlur={props?.blurEvent}
              />
              <label htmlFor="includeRoutes" className="ml-2">
                <span>Include Routes/Stops</span>
              </label>
            </div>
            <div
              className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
            >
              <Checkbox
                id="includeItemXRef"
                name="includeItemXRef"
                disabled={props?.isReadonly}
                checked={props?.values.includeItemXRef}
                onChange={props?.changeEvent}
                onBlur={props?.blurEvent}
              />
              <label htmlFor="includeItemXRef" className="ml-2">
                <span>Include ItemXref in XML</span>
              </label>
            </div>
            <div
              className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
            >
              <input
                id="allowNoSalesline"
                name="allowNoSalesline"
                type="checkbox"
                checked={props?.values.allowNoSalesline}
                onChange={props?.changeEvent}
                onBlur={props?.blurEvent}
                disabled
                className={props?.styles.disabledCheckBox}
              ></input>
              <label htmlFor="allowNoSalesline" className="ml-2">
                <span>Allow Duplicate Salesline XML</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="flex align-items-center">
          <input
            id="createPOCheck"
            name="createPOCheck"
            type="checkbox"
            checked={props?.values?.createPOCheck}
            onChange={props?.changeEvent}
            onBlur={props?.blurEvent}
            disabled
            className={props?.styles.disabledCheckBox}
          ></input>
          <label htmlFor="createPOCheck" className="ml-2">
            Create PO .csv (Dest: EDI Dir Warehouse: EDI Partner)
          </label>
        </div>
      </div>
      <div className="col-12 text-right">
        <Button
          label="Next"
          type="button"
          size="large"
          icon="pi pi-angle-right"
          iconPos="right"
          outlined
          className={props?.styles.secondaryBtn}
          onClick={changeTab}
        />
      </div>
    </div>
  );
};

export default GeneralInfoForm;
