import { api } from "../api/api";
import * as actionTypes from "./actions";

export const getRegions = () => async (dispatch, getState) => {
  try {
    if (!getState().orderReducer.regions.length) {
      const response = await api.get("/api/Regions");
      dispatch({
        type: actionTypes.GET_REGIONS,
        value: response.data?.result?.result || []
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.GET_REGIONS,
      value: ["error"]
    });
  }
};

export const getWalmartRegions = () => async (dispatch, getState) => {
  try {
    if (!getState().orderReducer.walmartRegions.length) {
      const response = await api.get(
        "/api/ediPurchaseOrder/switchboard/regions"
      );
      dispatch({
        type: actionTypes.GET_WALMART_REGIONS,
        value: response.data?.result?.result || []
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.GET_WALMART_REGIONS,
      value: ["error"]
    });
  }
};

export const getFunctions =
  ({ params }) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/Functions?statusId=${params?.statusId}&regionId=${params?.regionId}&isLogSummary=${params?.isLogSummary}&isAutoLogSummary=${params?.isAutoLogSummary}`
      );
      dispatch({
        type: actionTypes.GET_FUNCTIONS,
        value: { [params.regionId]: response.data?.result?.result } || {
          [params.regionId]: []
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getAllFunctions =
  ({ params }) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/Functions?statusId=${params?.statusId}&isLogSummary=${params?.isLogSummary}`
      );
      dispatch({
        type: actionTypes.GET_FUNCTIONS_ALL,
        value: response.data?.result?.result || []
      });
    } catch (error) {
      console.log(error);
    }
  };
export const getAllFunctionsForLogSummary =
  ({ params }) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/Functions?statusId=${params?.statusId}&isLogSummary=${params?.isLogSummary}&isAutoLogSummary=${params?.isAutoLogSummary}`
      );
      dispatch({
        type: actionTypes.GET_FUNCTIONS_ALL_For_LogSummary,
        value: response.data?.result?.result || []
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getFunctionMasterData = () => async (dispatch, getState) => {
  try {
    const response = await api.get("api/Functions/MasterData");
    dispatch({
      type: actionTypes.GET_FUNCTION_MASTERDATA,
      value: response.data?.result?.result || []
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_FUNCTION_MASTERDATA,
      value: ["error"]
    });
  }
};

export const getFunctionDetailas = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/Functions/${id}`);
    dispatch({
      type: actionTypes.GET_FUNCTION_DETAILS_BY_ID,
      value: response.data?.result?.result || []
    });
  } catch (error) {
    console.log(error);
  }
};
