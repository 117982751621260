import React, { useEffect, useState, memo, useRef,useContext } from "react";
import "primereact/resources/themes/saga-orange/theme.css";
import "primeicons/primeicons.css";
import "../../../node_modules/primeflex/primeflex.css";
import Table from "../../components/Table/Table";
import { createOrderColumns } from "../../constants/table-columns/tableColumns";
import { CreateOrderTableHeader } from "../../components/Table/table-header/tableHeader";
import { createOrderFooter } from "../../components/Table/table-footer/tablefooter";
import * as constants from "../../constants/constants";
import OrderSummary from "../orderSummary/order-summary";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Link } from 'react-router-dom';

import styles from "./processed-data.module.scss";
import Spinner from "../spinner/spinner";
import orderSummaryApi from "../../services/orderSummary";
import { saveAs } from 'file-saver';
import LoginUserDataContext from "../../store/login";
import { ProgressSpinner } from 'primereact/progressspinner';
import { appInsights } from '../../AppInsights';

const ProcessedData = (props) => {
  const { processedData } = props;
  const { fromscreen } = props;
  const { destination } = props;
  const statusList = [
    { label: "Error", value: 2 },
    { label: "Success", value: 1 }
  ];
  const toast = useRef(null);
  const [processeData, setProcesseData] = useState(processedData);
  const [loginUserData, setloginUserData] = useContext(LoginUserDataContext);
  const [errorCount, setErrorCount] = useState(0);
  const [position, setPosition] = useState("top-right");
  const [errorPosition, setErrorPosition] = useState("center");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchCustId, setSearchCustId] = useState("");
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [xmlFileList, setXMLFileList] = useState([]);
  const [successRecords, setSuccessRecords] = useState([]);
  const [noSuccessRecords, setNoSuccessRecords] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showOrderSummarySpinner, setShowOrderSummarySpinner] = useState(false);
  const [orderSummaryData, setOrderSummaryData] = useState([]);
  const [showXmlList, setShowXMLList] = useState(false);
  const [showXmlListError,setShowXMLListError] = useState(false)
  const [xmlListErrorText, setXmlListErrorText] = useState("")
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [errorExceptZeroChecked, setErrorrExceptZeroChecked] = useState(false)

  useEffect(() => {
    onClear();
    const errorRecords = processedData.filter((item) => item.errors != null);
    setErrorCount(errorRecords.length);
  }, [processedData]);

  useEffect(()=>{
    if(xmlFileList?.filenames?.length >0){
     props?.setRestrictNavigation(false);
    }
  },[xmlFileList])

  const filterContent = (selectedStatus,searchCustId,errorExceptZeroChecked ) =>{
    let filterContent = processedData;
    if(selectedStatus){
      filterContent = filterContent.filter((item) => {
        if (selectedStatus === 1 && item.errors == null) {
          return item;
        } else if (selectedStatus === 2 && item.errors != null) {
          return item;
        }
      });
    }
    if(searchCustId?.trim() != ""){
      filterContent = filterContent.filter((item) =>
       (item?.custId?.toLocaleLowerCase().includes(searchCustId))
      );
    }
    if(searchCustId?.trim() == ""){
      filterContent = filterContent.filter((item) =>
        (item?.custId?.toLocaleLowerCase().includes(searchCustId) || item?.custId == null)
       );
    }
    if(errorExceptZeroChecked){
      filterContent = filterContent.filter((item) => {
        if (
          item.errors == null ||
          item.errors?.length > 1 ||
          validateErrorWithZero(item.errors)
        ) {
          return item;
        }
      });
    }
    if(!errorExceptZeroChecked){
      filterContent = filterContent.filter((item) => {
       return item
      });
    }
    setProcesseData(filterContent);
  }
  const onStatusSelect = (event) => {
    const data = event?.value?.label ? event?.value?.value : event?.value;
    setSelectedStatus(data);
    if(searchCustId?.trim() == "" && !errorExceptZeroChecked){
      const filteredData = processedData.filter((item) => {
        if (data === 1 && item.errors == null) {
          return item;
        } else if (data === 2 && item.errors != null) {
          return item;
        }
      });
      setProcesseData(filteredData);
    }  
    else{
      filterContent(data,searchCustId,errorExceptZeroChecked )
    }
  }
  const onSearch = (event) => {
    const value = event.target.value;
    setSearchCustId(value);
    if(selectedStatus == null && !errorExceptZeroChecked && value?.trim() !== ""){
      const filteredData = processedData.filter((item) =>
        item?.custId?.toLocaleLowerCase().includes(value)
      );
      setProcesseData(filteredData);
    }
    else{
      filterContent(selectedStatus,value,errorExceptZeroChecked )
    }
  }
  const onErrorExceptZeroSelect = (event) => {
    setErrorrExceptZeroChecked(event?.checked);
    if(selectedStatus == null && searchCustId?.trim() == "" && event?.checked){
    const filteredData = processedData.filter((item) => {
      if (
        item.errors == null ||
        item.errors?.length > 1 ||
        validateErrorWithZero(item.errors)
      ) {
        return item;
      }
    });
    setProcesseData(filteredData);
  }
  else{
    filterContent(selectedStatus,searchCustId,event?.checked)
  }
  }
  const validateErrorWithZero = (errors) =>{
      if(errors[0]?.includes("Quantity is zero or empty")){
        return false
      }
      else{
        return true
      }
  }
  const onD365Click = () => {
    const errorCount = processeData.filter((item) => item?.errors !== null);
    if (errorCount.length === processeData.length) {
      setNoSuccessRecords(true);
      setShowOrderSummary(true);
    } else {
      setShowSpinner(true);
      calculateOrderSummaryData(processeData);
    }
  };

  const calculateOrderSummaryData = (processeData) => {
    try {
      const filteredSuccessRecords = processeData.filter(
        (item) => item.errors === null
      );
      // setSuccessRecords(filteredSuccessRecords);
       setSuccessRecords(processeData);
      const custCount = Array.from(
        filteredSuccessRecords
          .reduce((m, t) => m.set(t?.custId, t), new Map())
          .values()
      );
      const summaryData = filteredSuccessRecords.reduce((acc, item) => {
        const existingItem = acc.find(
          (summaryItem) => summaryItem.dstItem === item.dstItem
        );
        if (existingItem) {
          existingItem.quantity += item.total;
        } else {
          acc.push({ dstItem: item.dstItem, quantity: item.total });
        }
        return acc;
      }, []);
      const orderSummaryObj = {
        functionId: filteredSuccessRecords[0]?.functionId,
        currentStoreCount: custCount?.length,
        itemListRequestDtos: []
      };
      summaryData?.map((item) => {
        orderSummaryObj.itemListRequestDtos.push({
          itemId: item?.dstItem,
          quantity: item?.quantity
        });
      });
      getOrderSummaryData(orderSummaryObj);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  const getOrderSummaryData = async (orderSummaryObj) => {
    try {
      const result = await orderSummaryApi(orderSummaryObj);
      setOrderSummaryData(result);
      setShowSpinner(false);
      setNoSuccessRecords(false);
      setShowOrderSummary(true);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 5000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while getting order summary",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const onClear = () => {
    setProcesseData(processedData);
    setSelectedStatus(null);
    setSearchCustId("");
    setErrorrExceptZeroChecked(false)
  };


const exportExcel = () => {
  if(processeData?.length > 0){
  import('xlsx').then((xlsx) => {
    const columnsToExport = ['shipReqDateStr','custId', 'custName', 'wrhs', 'dstItem', 'name', 'srcItem','uom','sun','mon','tue','wed','thu','fri','sat','total', 'srcStore','customerPO', 'purchaseList','receiptDateStr','invAccount','route','stop','errors']; // Define columns to export
      const filteredProducts = processeData?.map(processeData => {
          // Convert the errors array to a string
          const errorsString = processeData.errors ? processeData.errors.join('; ') : '';

          return columnsToExport.reduce((obj, key) => {
              // If the current key is 'errors', use the errorsString
              obj[key] = key === 'errors' ? errorsString : processeData[key];
              return obj;
          }, {});
      });

      const worksheet = xlsx.utils.json_to_sheet(filteredProducts);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
      });

      saveAsExcelFile(excelBuffer, `${processeData[0]?.customer}-${processeData[0]?.customerPO}`);
  });
}
};


const saveAsExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(data, fileName + '.xlsx');
};

  const errorBodyTemplate = (rowData) => {
    return rowData?.errors?.join(", ");
  };

  const clearForm =() =>{
    setShowXMLList(false);
    props?.clearForm();
  }
  const handleConfirmationDialog = () =>{
    props?.handleConfirmation()
  }

  const footerContent = (
    <div>
                  <span className={styles.confirmationText}>
              An email confirmation will be sent to <a href={`mailto:${loginUserData?.emailAdrress}`}  className={styles.confirmationMail}>{loginUserData?.emailAdrress}</a> once
              processing is completed. You can also check the real time status
              from  <Link to={`/ofp/orders/logsummary?functionId=${processeData[0]?.functionId }`} className={styles.confirmationText}>Log Summary</Link>.
            </span>
            <div className="grid" style={{paddingTop:'12px'}}>
              <div className="col-2">
              <Button
              label="OK"
              type="button"
              onClick={clearForm}
              className={styles.primaryBtn}
            />
              </div>
            </div>
    </div>
);

  return (
    <>
      {showSpinner && <Spinner />}
      {errorCount > 0 && (
        <div className={styles.errorContainer}>
          <span>
            {" "}
            <i className="pi pi-info-circle"> </i> There are {errorCount} errors
            in the data.
          </span>
        </div>
      )}
      <div>
        <Table
          content={processeData}
          columnsData={createOrderColumns(setShowErrorDialog, setErrorList)}
          rows={constants.defaultRows}
          rowsPerPageOptions={constants.rowsPerPageOptions}
          paginator={constants.paginator}
          scrollable={constants.scrollable}
          emptyMessage={constants.emptyMessage}
          tableStyle={constants.tableStyle}
          currentPageReportTemplate={constants.currentPageReportTemplate}
          paginatorTemplate={constants.paginatorTemplate}
          createOrderErrorSplit={errorBodyTemplate}
          header={(copyTable, isCopyEnable, data) =>
            CreateOrderTableHeader({
              selectedStatus,
              onStatusSelect,
              onErrorExceptZeroSelect,
              errorExceptZeroChecked,
              statusList,
              styles,
              searchCustId,
              onSearch,
              onClear,
              exportExcel,
              data,
              copyTable,
              isCopyEnable
            })
          }
          footer={() =>
            createOrderFooter({ styles, onD365Click, processeData, destination, handleConfirmationDialog, fromscreen })
          }
          isCopyEnable={true}
        />
        {showErrorDialog && (
                <Dialog
                header="Error list"
                visible={showErrorDialog}
                style={{ width: "50vw" }}
                position={errorPosition}
                onHide={() => setShowErrorDialog(false)}
              >
                 <ul className={styles.xmlList}>
              {errorList?.map((item) => {
                return <li className={styles.xmlListItem}>{item}</li>;
              })}
            </ul>
              </Dialog>
        )}
        {showOrderSummary && (
          <Dialog
            header="Order Summary"
            visible={showOrderSummary}
            style={{ width: "75vw" }}
            position={position}
            onHide={() => setShowOrderSummary(false)}
          >
            {showOrderSummarySpinner && <Spinner />}
            {/* <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
            {noSuccessRecords ? (
              <div className={`${styles.orderSummary_Band} ${styles.green}`}>
                No Success Records to move forward!
              </div>
            ) : (
              <>
                <div
                  className={
                    orderSummaryData?.acceptableRangeFlag
                      ? `${styles.green} ${styles.orderSummary_Band}`
                      : `${styles.red} ${styles.orderSummary_Band}`
                  }
                >
                  {orderSummaryData?.acceptableRangeFlag ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M14.25 17.7504H2.63C2.00223 17.7504 1.39988 17.5024 0.954118 17.0604C0.508351 16.6184 0.255275 16.0182 0.25 15.3904V9.75041C0.252645 9.12093 0.504561 8.51814 0.950612 8.07396C1.39666 7.62978 2.00051 7.38041 2.63 7.38041H4.63L7.36 1.29041C7.54647 0.874163 7.88808 0.547161 8.31208 0.379056C8.73608 0.21095 9.20896 0.215025 9.63 0.390413C10.1927 0.639694 10.6704 1.04787 11.0043 1.56481C11.3383 2.08176 11.5141 2.68498 11.51 3.30041V5.77041H15.51C15.5663 5.7609 15.6237 5.7609 15.68 5.77041C16.3139 5.8926 16.8783 6.24978 17.26 6.77041C17.6206 7.2514 17.7785 7.85443 17.7 8.45041L16.6 15.7404C16.5119 16.3004 16.2267 16.8106 15.7959 17.1791C15.3651 17.5476 14.8169 17.7502 14.25 17.7504V17.7504ZM5.82 16.2504H14.25C14.4584 16.2531 14.6609 16.1809 14.8205 16.047C14.9802 15.913 15.0865 15.7261 15.12 15.5204L16.24 8.26041C16.2538 8.16107 16.2467 8.05995 16.2192 7.96351C16.1916 7.86708 16.1442 7.77746 16.08 7.70041C15.9176 7.48563 15.6833 7.33653 15.42 7.28041H10.75C10.6503 7.2818 10.5513 7.26301 10.459 7.22518C10.3667 7.18735 10.2831 7.13125 10.213 7.06027C10.143 6.98928 10.088 6.90487 10.0514 6.81209C10.0148 6.71932 9.99729 6.62011 10 6.52041V3.30041C10.0013 2.97551 9.9073 2.65735 9.72954 2.38538C9.55179 2.11342 9.29812 1.89959 9 1.77041C8.9416 1.74717 8.87641 1.74777 8.81844 1.77208C8.76047 1.79638 8.71435 1.84246 8.69 1.90041L5.82 8.29041V16.2504ZM2.63 8.88041C2.51491 8.87909 2.4007 8.90062 2.29399 8.94375C2.18728 8.98687 2.09019 9.05075 2.00834 9.13167C1.92649 9.21259 1.86151 9.30894 1.81717 9.41515C1.77282 9.52136 1.74999 9.63532 1.75 9.75041V15.3904C1.75131 15.5047 1.7751 15.6175 1.82004 15.7226C1.86497 15.8277 1.93015 15.9228 2.01187 16.0027C2.09359 16.0825 2.19024 16.1455 2.2963 16.188C2.40235 16.2305 2.51575 16.2517 2.63 16.2504H4.32V8.88041H2.63Z"
                        fill="#22C55E"
                      />
                    </svg>
                  ) : (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    style={{transform: 'rotateX(180deg)'}}
                  >
                    <path
                      d="M9.49935 11.833H1.75268C1.33417 11.833 0.932605 11.6676 0.635427 11.373C0.33825 11.0783 0.169533 10.6781 0.166016 10.2596V6.49962C0.167779 6.07997 0.335723 5.67811 0.63309 5.38199C0.930457 5.08587 1.33302 4.91962 1.75268 4.91962H3.08602L4.90602 0.859624C5.03033 0.582124 5.25807 0.364123 5.54074 0.252053C5.8234 0.139982 6.13866 0.142699 6.41935 0.259624C6.79448 0.425812 7.11292 0.697926 7.33557 1.04256C7.55822 1.38719 7.67538 1.78934 7.67268 2.19962V3.84629H10.3393C10.3769 3.83995 10.4152 3.83995 10.4527 3.84629C10.8753 3.92775 11.2515 4.16587 11.506 4.51296C11.7464 4.83361 11.8517 5.23564 11.7993 5.63296L11.066 10.493C11.0073 10.8663 10.8172 11.2064 10.53 11.4521C10.2428 11.6977 9.87729 11.8328 9.49935 11.833V11.833ZM3.87935 10.833H9.49935C9.63829 10.8348 9.77326 10.7866 9.87971 10.6973C9.98616 10.608 10.057 10.4834 10.0793 10.3463L10.826 5.50629C10.8352 5.44007 10.8305 5.37265 10.8121 5.30836C10.7937 5.24407 10.7621 5.18433 10.7193 5.13296C10.6111 4.98977 10.4549 4.89037 10.2793 4.85296H7.16602C7.09953 4.85388 7.03355 4.84136 6.97203 4.81613C6.91051 4.79091 6.85473 4.75352 6.80802 4.70619C6.76132 4.65887 6.72467 4.60259 6.70027 4.54074C6.67586 4.4789 6.66421 4.41276 6.66602 4.34629V2.19962C6.66691 1.98302 6.60422 1.77092 6.48571 1.58961C6.36721 1.40829 6.1981 1.26574 5.99935 1.17962C5.96041 1.16413 5.91695 1.16453 5.87831 1.18073C5.83966 1.19694 5.80892 1.22766 5.79268 1.26629L3.87935 5.52629V10.833ZM1.75268 5.91962C1.67596 5.91874 1.59982 5.93309 1.52868 5.96185C1.45754 5.9906 1.39281 6.03318 1.33824 6.08713C1.28368 6.14107 1.24036 6.20531 1.21079 6.27612C1.18123 6.34692 1.16601 6.42289 1.16602 6.49962V10.2596C1.16689 10.3358 1.18275 10.411 1.21271 10.4811C1.24266 10.5511 1.28612 10.6146 1.3406 10.6678C1.39507 10.721 1.45951 10.763 1.53021 10.7914C1.60092 10.8197 1.67651 10.8338 1.75268 10.833H2.87935V5.91962H1.75268Z"
                      fill="white"
                    />
                  </svg>
                  )}
                  <span style={{color:orderSummaryData?.acceptableRangeFlag ? 'black':'white'}}> {orderSummaryData?.headerRangeDescription}</span>
                </div>
                <div className={styles.orderSummaryTableContainer}>
                  <OrderSummary
                    orderSummaryData={orderSummaryData}
                    filteredSuccessRecords={successRecords}
                    processedData ={processedData}
                    setShowOrderSummary={setShowOrderSummary}
                    setShowXMLList={setShowXMLList}
                    setXMLFileList={setXMLFileList}
                    setShowOrderSummarySpinner={setShowOrderSummarySpinner}
                    setShowXMLListError={setShowXMLListError}
                    setXmlListErrorText={setXmlListErrorText}
                  />
                </div>
              </>
            )}
          </Dialog>
        )}
        {showXmlList && (
          <Dialog
            header="Confirm Action"
            visible={showXmlList}
            style={{ width: "50vw" }}
            className="xmlListDialog"
            onHide={() => setShowXMLList(false)}
            footer={footerContent}
          >
            <div className={`${styles.orderSummary_Band} ${styles.green}`}>
              <i className="pi pi-check-circle" style={{color:'#22c55e', paddingRight:'16px'}}/>Below {xmlFileList?.filenames?.length} XMLs are created and will be processed shortly
            </div>
            <div className={styles?.xmlListHeading}>
              XML File Name
            </div>
            <ul className={styles.xmlList}>
              {xmlFileList?.filenames?.map((item) => {
                return <li className={styles.xmlListItem}>{item}</li>;
              })}
            </ul>
          </Dialog>
        )}
        {showXmlListError && (
          <Dialog
            header="Alert"
            visible={showXmlListError}
            style={{ width: "50vw" }}
            className="xmlListDialog"
            onHide={() => setShowXMLListError(false)}
          >
            <div className={`${styles.red} ${styles.orderSummary_Band}`} style={{color:'white'}}>
            {xmlListErrorText}
            </div>
          </Dialog>
        )}
        <Toast ref={toast} position="center" />
      </div>
    </>
  );
};

export default memo(ProcessedData);
